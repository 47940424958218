import Popup from "@/components/Popup.vue";
import EnergyClass from "@/components/EnergyClass.vue";
import Footer from "@/components/co-footer/co-footer.vue";
import axios from "axios";
import { getLocaleElement } from "@/lib/locales.js";

export default {
  name: "PropertyBuilder",
  components: { EnergyClass, Footer, Popup },
  data() {
    return {
      propertyId: 0,
      propertyData: [],

      propertyGeoCode: {},

      propertyInfoSurface: 0,
      propertyInfoBedrooms: 0,
      propertyInfoBathrooms: 0,
      propertyInfoParking: 0,
      propertyInfoArea: 0,

      propertyInfoLocation: 0,
      propertyInfoPrice: 0,
      propertyInfoNature: 0,

      propertyEnergyClass: undefined,
      propertyIsolationClass: undefined,

      propertyDescription: "",

      propertyPhotos: [],

      photoFullScreen: false,
      photoFullScreenUrl: "",

      propertyMapMarkerOpen: false,
      propertyMapMarkerText: "",
      propertyMapMarkerTitle: "",

      propertyArrange: "",

      propertyTextDescription: "",
      propertyTextEnergy: "",
      propertyTextIsolation: "",

      scrollSpeed: 750,

      propertyAgent: {},
    };
  },
  async mounted() {
    let self = this;

    window.addEventListener("keyup", function (e) {
      if (e.key === "Escape") {
        self.closeModal();
      }
    });

    this.propertyMapMarkerTitle = await getLocaleElement("lang-propertylist-mapinfo-title");
    this.propertyMapMarkerText = await getLocaleElement("lang-propertylist-mapinfo-text");

    this.propertyTextDescription = await getLocaleElement("lang-properties-description");
    this.propertyTextEnergy = await getLocaleElement("lang-properties-energy");
    this.propertyTextIsolation = await getLocaleElement("lang-properties-isolation");

    this.propertyArrange = await getLocaleElement("lang-propertylist-arrange");

    const scrollbarWidth = window.innerWidth - document.body.clientWidth;
    document.body.style.setProperty("--scrollbarWidth", `${scrollbarWidth}px`);

    this.propertyId = this.$route.params.property;

    var propertyId = this.propertyId;
    console.log("mounted");
    console.log(propertyId);

    let locale = localStorage.getItem("immeck_lang");

    let query = process.env.VUE_APP_CMS_DOMAIN + "/api/properties/" + propertyId + "?locale=" + locale + "&populate=*";

    console.log(query);

    axios.get(query).then((response) => {
      let res = response.data.data;
      this.propertyData = res;

      this.propertyInfoSurface = Math.round(res.attributes.Surface);
      this.propertyInfoBedrooms = res.attributes.Bedrooms;
      this.propertyInfoBathrooms = res.attributes.Bathrooms;
      this.propertyInfoParking = res.attributes.Parking;
      this.propertyInfoArea = res.attributes.SurfaceArea;

      // Fallback
      this.propertyDescription = res.attributes.Description;

      if (locale == "en") {
        let localDescription = res.attributes.localizations.data[0].attributes.Description;

        if (localDescription) {
          this.propertyDescription = localDescription;
        }
      }

      this.propertyInfoLocation = res.attributes.Location;

      if (localStorage.getItem("immeck_lang") == "en") {
        let ntr = "";
        switch (res.attributes.Nature) {
          case "Appartement":
            ntr = "Apartment";
            break;
          case "Maison":
            ntr = "House";
            break;
          case "Commerce":
            ntr = "Commercial";
            break;
          case "Terrain":
            ntr = "Terrain";
            break;
          case "Garage":
            ntr = "Garage";
            break;
          default:
            ntr = res.attributes.Nature;
        }

        this.propertyInfoNature = ntr;
      } else {
        this.propertyInfoNature = res.attributes.Nature;
      }

      this.propertyInfoPrice = res.attributes.Price;

      if (res.attributes.Energie != "null") {
        this.propertyEnergyClass = res.attributes.Energie;
      }
      if (res.attributes.Isolation != "null") {
        this.propertyIsolationClass = res.attributes.Isolation;
      }

      let agentidcms = res.attributes.Commerciaux;

      if (agentidcms == 1000196) {
        agentidcms = 200046;
      }

      var photos = res.attributes.Photo;
      var result = Object.entries(photos);

      var newResult = [];
      for (var i = 0; i < result.length; i++) {
        newResult.push(result[i][1].PropertyPhotoUrl);
      }

      this.propertyPhotos = newResult;

      this.geocode(res.attributes.Location);

      axios.get(process.env.VUE_APP_CMS_DOMAIN + "/api/agents/?populate=*&locale=" + locale).then((response) => {
        let res = response.data.data;

        for (let i = 0; i < res.length; i++) {
          const element = res[i];

          const agentid = element.attributes.AgentId;
          if (agentid == agentidcms) {
            this.propertyAgent = element.attributes;
            this.propertyAgent.PhotoURL = process.env.VUE_APP_CMS_DOMAIN + element.attributes.Photo.data.attributes.url;
          }
        }

        console.log(res);
      });
    });
  },
  methods: {
    getPhoto(url) {
      return {
        backgroundImage: "url(" + url + ")",
      };
    },
    openMapMarker() {
      this.propertyMapMarkerOpen = true;
    },
    openPhoto(url) {
      console.log(url);
    },
    closeModal() {
      console.log("close modal");
      this.photoFullScreen = false;
    },
    openOverlayPhoto(url) {
      this.photoFullScreenUrl = url;
      this.photoFullScreen = true;
    },
    nextModalPhoto(src) {
      let index = 0;
      for (let i = 0; i < this.propertyPhotos.length; i++) {
        const element = this.propertyPhotos[i];
        if (element == src) index = i;
      }

      if (index + 1 < this.propertyPhotos.length) {
        this.photoFullScreenUrl = this.propertyPhotos[index + 1];
        this.photoFullScreen = true;
      } else {
        this.photoFullScreenUrl = this.propertyPhotos[0];
        this.photoFullScreen = true;
      }
    },
    previousModalPhoto(src) {
      let index = 0;
      for (let i = 0; i < this.propertyPhotos.length; i++) {
        const element = this.propertyPhotos[i];
        if (element == src) index = i;
      }

      if (index > 0) {
        this.photoFullScreenUrl = this.propertyPhotos[index - 1];
        this.photoFullScreen = true;
      } else {
        this.photoFullScreenUrl = this.propertyPhotos[this.propertyPhotos.length - 1];
        this.photoFullScreen = true;
      }
    },
    async geocode(addresstext) {
      var API_KEY = "AIzaSyAmFwdTsSrg1Yb3SpVE0BVcEb9xFoZ8BGc";
      var BASE_URL = "https://maps.googleapis.com/maps/api/geocode/json?address=";

      var url = BASE_URL + addresstext + " Luxembourg" + "&key=" + API_KEY;
      axios.get(url).then((response) => {
        if (response.data.results.length > 0) {
          this.propertyGeoCode = response.data.results[0].geometry.location;
        }
      });
    },
    getWANumber(number) {
      // Convert phone number
      if (number) {
        let numberConvert = number.replace("+", "00").replace(" ", "").replace(" ", "").replace(" ", "").replace(" ", "");

        //let wa = "intent://send/" + numberConvert + "#Intent;scheme=smsto;package=com.whatsapp;action=android.intent.action.SENDTO;end";
        let wa = "tel:" + numberConvert;

        return wa;
      }
    },
    scrollLeft() {
      let element = document.getElementById("horizontalscroll");
      if (element.scrollLeft - this.scrollSpeed > 0) {
        element.scroll({
          left: element.scrollLeft - this.scrollSpeed,
          behavior: "smooth",
        });
      } else {
        element.scroll({
          left: 0,
          behavior: "smooth",
        });
      }
    },
    scrollRight() {
      let element = document.getElementById("horizontalscroll");
      element.scroll({
        left: element.scrollLeft + this.scrollSpeed,
        behavior: "smooth",
      });
    },
  },
  computed: {
    openModal() {
      return this.photoFullScreen;
    },
  },
};
