<template>
  <div class="property-item">
    <div class="property-item-flex img" :style="getPhoto(property.attributes.PropertyPhotoUrl)">
      <div class="property-item-icon-container">
        <div class="property-icon-list list">
          <div class="property-icon-container">
            <img class="property-icon list" src="@/assets/icons/property/surface.svg" />
            {{ Math.round(property.attributes.Surface) }} m2
          </div>
          <div class="property-icon-container" v-if="property.attributes.Bedrooms != 0 && property.attributes.Bedrooms != 'null'">
            <img class="property-icon list" src="@/assets/icons/property/bedrooms.svg" />
            {{ property.attributes.Bedrooms }}
          </div>
          <div class="property-icon-container" v-if="property.attributes.Bathrooms != 0 && property.attributes.Bathrooms != 'null'">
            <img class="property-icon list" src="@/assets/icons/property/bathrooms.svg" />
            {{ property.attributes.Bathrooms }}
          </div>
          <div class="property-icon-container" v-if="property.attributes.Parking != 0 && property.attributes.Parking != 'null'">
            <img class="property-icon list" src="@/assets/icons/property/parking.svg" />
            {{ property.attributes.Parking }}
          </div>
          <div class="property-icon-container" v-if="property.attributes.SurfaceArea != 0 && property.attributes.SurfaceArea != 'null'">
            <img class="property-icon list" src="@/assets/icons/property/terrain.svg" />
            {{ property.attributes.SurfaceArea }} ares
          </div>
        </div>
      </div>
    </div>
    <div class="property-item-flex">
      <h2 class="property-title">{{ property.attributes.Location }}</h2>
      <h3 class="property-subtitle">
        {{ generateSubtitle(property.attributes.Sell, property.attributes.Nature) }}
      </h3>
      <h2 class="property-price">{{ numberWithCommas(property.attributes.Price) }}€</h2>
    </div>
  </div>
</template>

<script src="./js/co-property-item.js"></script>

<style scoped>
@import "./css/co-property-item.css";
@import "./css/co-property-item-mobile.css";
</style>
