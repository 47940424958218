<template>
  <div class="pb-pageformattext-container">
    <div class="pb-pageformattext">
      <vue-markdown :source="richtext" />
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown-render";

export default {
  name: "pbpageformattext",
  props: {
    schema: {
      type: Object,
    },
  },

  components: {
    VueMarkdown,
  },
  data() {
    return {
      richtext: "",
    };
  },
  mounted() {
    if (this.schema.RichText != null) this.richtext = this.schema.RichText;
  },
};
</script>

<style>
.pb-pageformattext-container {
  padding: 0 10vw 0 10vw;

  margin: 0 0 10vh 0;
}
.pb-pageformattext {
  text-align: left;

  font-family: "Roboto", sans-serif;

  font-size: 12pt;
}

.pb-pageformattext p {
  color: rgb(99, 99, 99);

  font-weight: 400;
  padding: 0;
  margin: 0;

  line-height: 20pt;
}

.pb-pageformattext p strong {
  color: black;

  font-weight: 400;
  margin: 10vh 0 0 0;

  line-height: 40pt;
}
</style>
