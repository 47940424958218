<template>
  <div class="imagify-pagebuilder">
    <div class="document-builder">
      <h2>{{ title }}</h2>
      <h3>Documents</h3>
      <div class="document-list-container">
        <div class="document-list-item" v-for="document in documentList" :key="document">
          <a :href="serverLink + document.attributes.url">{{ document.attributes.caption }}</a>
        </div>
      </div>
    </div>

    <div v-for="(schema, index) in componentList" :key="index">
      <component :key="index" :is="schema.type" :schema="schema"></component>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";

import Footer from "@/components/co-footer/co-footer.vue";

import pbpagetext from "@/pagebuilder/pb-pagetext/pb-pagetext.vue";

import pblargeimage from "@/pagebuilder/pb-largeimage.vue";
import pbpagetitle from "@/pagebuilder/pb-pagetitle.vue";
import pbpageformattext from "@/pagebuilder/pb-pageformattext.vue";

export default {
  data() {
    return {
      pageName: null,
      setupComplete: false,
      componentList: [],
      documentList: "",
      serverLink: "",
      title: "Undefined",
    };
  },
  components: {
    Footer,
    pblargeimage,
    pbpagetext,
    pbpagetitle,
    pbpageformattext,
  },
  methods: {
    setup: function () {
      // Get Page Name
      if (!this.setupComplete) {
        this.pageName = this.$route.params.project;

        let query = "/api/projects?filters[ProjectUID]=" + this.pageName + "&populate=deep";

        axios.get(process.env.VUE_APP_CMS_DOMAIN + query).then((response) => {
          let data = response.data.data;
          if (data.length == 0) {
            // Page not found
            let query = "/api/pages?filters[PageUID]=404&populate=deep";
            axios.get(process.env.VUE_APP_CMS_DOMAIN + query).then((response) => {
              let data = response.data.data;
              if (data.length == 0) {
                console.log("404 Page not found");
              } else {
                this.buildPage(data);
              }
            });
          } else {
            // Build Project
            let documentList = data[0].attributes.Documents;
            this.serverLink = process.env.VUE_APP_CMS_DOMAIN;
            this.documentList = documentList.data;

            this.title = data[0].attributes.Name;

            if (documentList.data.length > 0 && documentList.data.length < 2) {
              window.location.href = this.serverLink + documentList.data[0].attributes.url;
            }
          }
        });

        this.setupComplete = true;
      }
    },
    buildPage: function (data) {
      let components = data[0].attributes.Content;
      for (var i = 0; i < components.length; i++) {
        components[i].type = "pb" + components[i].__component.split("pagebuilder.").pop().replaceAll("-", "");
        this.componentList.push(components[[i]]);
      }
    },
  },
  mounted() {
    this.setup();
  },
  updated() {
    this.setup();
  },
};
</script>

<style>
.document-builder {
  text-align: left;

  padding: 10vh 10vw 10vh 10vw;
}

.document-list-container {
  display: flex;
}

.document-list-item {
  line-height: 20pt;

  font-weight: 400;
  color: #444;

  height: 10vh;
  width: 20%;

  margin: 1em;

  border: 0.2em solid #79232f;

  display: flex;

  align-items: center;
  justify-content: center;

  transform: skewX(40deg);

  transition: all 250ms;
}

.document-list-item a {
  transform: skewX(-40deg);

  color: #79232f;
  text-decoration: none;
}

.document-list-item:first-child {
  margin: 1em 1em 1em 5vh;
}

.document-list-item:hover {
  background-color: #79232f;
}

.document-list-item:hover a {
  color: white;
}
</style>
