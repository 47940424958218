<template>
  <div class="popup-main-container">
    <Teleport to="body">
      <Transition>
        <div v-if="isOpen" class="overlay-background" id="overlay-background" @click="closeModal">
          <div class="overlay-modal" id="overlay-modal">
            <img class="overlay-img" :src="src" />
            <img src="@/assets/icons/popup/chevron_left.svg" class="popup-navigation left" @click="previousPhoto($event)" />
            <img src="@/assets/icons/popup/chevron_right.svg" class="popup-navigation right" @click="nextPhoto($event)" />
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script>
export default {
  name: "Popup",
  props: {
    open: Boolean,
    src: String,
  },
  methods: {
    closeModal: function (e) {
      if (e.target.id == "overlay-background" || e.target.id == "overlay-modal") {
        this.$emit("close");
      }
    },
    nextPhoto: function () {
      this.$emit("nextPhoto", this.src);
    },
    previousPhoto: function () {
      this.$emit("previousPhoto", this.src);
    },
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (this.open) {
        if (e.key == "ArrowLeft") {
          e.preventDefault();
          this.previousPhoto();
        }
        if (e.key == "ArrowRight") {
          e.preventDefault();
          this.nextPhoto();
        }
      }
    });
  },
  computed: {
    isOpen() {
      return this.open;
    },
  },
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.overlay-background {
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.5);

  display: flex;

  align-items: center;
  justify-content: center;
}

.overlay-modal {
  position: relative;
  width: 80vw;
  height: 80vh;

  display: flex;

  align-items: center;
  justify-content: center;
}

.overlay-img-container {
  max-width: 100%;
  max-height: 100%;

  background-color: green;

  position: relative;
}

.overlay-img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 1em;

  box-shadow: 0 0 20vh 0 rgba(0, 0, 0, 0.25);
  border: 1em solid white;
}

.popup-navigation {
  position: absolute;
  top: 50%;
  left: 0;

  transform: translateY(-50%);

  height: 7vh;

  transition: all 250ms;
}

.popup-navigation.left {
  left: -5vw;
}

.popup-navigation.left:hover {
  transform: translate(-15%, -50%);
  cursor: pointer;
}

.popup-navigation.right {
  left: auto;
  right: -5vw;
}

.popup-navigation.right:hover {
  transform: translate(15%, -50%);
  cursor: pointer;
}
</style>
