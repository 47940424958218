<template>
  <div class="page-home pages">
    <Popup :open="openModal" @close="closeModal" @nextPhoto="nextModalPhoto" @previousPhoto="previousModalPhoto" :src="photoFullScreenUrl"> </Popup>

    <div class="page-flex-container">
      <div class="page-property-item-nav left" @click="scrollLeft($event)">
        <img src="@/assets/icons/popup/chevron_left.svg" class="page-property-item-nav-icon left" />
      </div>
      <div class="page-property-item-nav right" @click="scrollRight($event)">
        <img src="@/assets/icons/popup/chevron_right.svg" class="page-property-item-nav-icon right" />
      </div>
      <div class="page-horizontal-scroll property-images" id="horizontalscroll">
        <div class="page-property-item" v-for="photo in propertyPhotos" :key="photo">
          <div class="page-property-item-bg" :style="getPhoto(photo)" @click="openOverlayPhoto(photo)"></div>
        </div>
      </div>

      <div class="property-sides bg">
        <div class="property-side left">
          <div class="property-icon-list">
            <div class="property-icon-container">
              <img class="property-icon" src="@/assets/icons/property/surface.svg" />
              {{ propertyInfoSurface }} m2
            </div>
            <div class="property-icon-container" v-if="propertyInfoBedrooms != 0 && propertyInfoBedrooms != 'null'">
              <img class="property-icon" src="@/assets/icons/property/bedrooms.svg" />
              {{ propertyInfoBedrooms }}
            </div>
            <div class="property-icon-container" v-if="propertyInfoBathrooms != 0 && propertyInfoBathrooms != 'null'">
              <img class="property-icon" src="@/assets/icons/property/bathrooms.svg" />
              {{ propertyInfoBathrooms }}
            </div>
            <div class="property-icon-container" v-if="propertyInfoParking != 0 && propertyInfoParking != 'null'">
              <img class="property-icon" src="@/assets/icons/property/parking.svg" />
              {{ propertyInfoParking }}
            </div>
            <div class="property-icon-container" v-if="propertyInfoArea != 0 && propertyInfoArea != 'null'">
              <img class="property-icon" src="@/assets/icons/property/terrain.svg" />
              {{ propertyInfoArea }} ares
            </div>
          </div>

          <div class="property-title-container">
            <h2>{{ propertyInfoLocation }}</h2>
            <label class="property-title-description">{{ propertyInfoNature }}</label>
            <label class="property-title-description">{{ propertyInfoPrice.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.") + " €" }}</label>
          </div>
        </div>
        <div class="property-side right">
          <div class="property-map" v-if="Object.keys(propertyGeoCode).length > 0">
            <GMapMap
              :center="propertyGeoCode"
              :zoom="14"
              :options="{
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
              }"
            >
              <GMapMarker :position="propertyGeoCode" icon="/assets/icons/property/mapmarker.png" @click="openMapMarker()" :clickable="true">
                <GMapInfoWindow :opened="propertyMapMarkerOpen" :closeclick="true" @closeclick="propertyMapMarkerOpen = false">
                  <div class="marker-infobox">
                    <h2 class="marker-infobox-title">
                      {{ propertyMapMarkerTitle }}
                    </h2>
                    <p class="marker-infobox-description">
                      {{ propertyMapMarkerText }}
                    </p>
                  </div>
                </GMapInfoWindow>
              </GMapMarker>
            </GMapMap>
          </div>
        </div>
      </div>
    </div>

    <div class="property-sides">
      <div class="property-side left">
        <h2 class="property-site-description-title">
          {{ propertyTextDescription }}
        </h2>
        <p class="property-site-description" v-html="propertyDescription"></p>
      </div>
      <div class="property-side right">
        <div class="property-energy-energy" v-if="propertyEnergyClass != undefined">
          <h2>{{ propertyTextEnergy }}</h2>
          <EnergyClass :value="propertyEnergyClass"></EnergyClass>
        </div>
        <br />
        <div class="property-energy-isolation" v-if="propertyIsolationClass != undefined">
          <h2>{{ propertyTextIsolation }}</h2>
          <EnergyClass :value="propertyIsolationClass"></EnergyClass>
        </div>

        <h2 class="agent-title">{{ propertyArrange }}</h2>
        <div class="agent-container">
          <div class="agent-bg-container">
            <h2>
              {{ propertyAgent.Firstname }}
              {{ propertyAgent.Lastname }}
            </h2>
            <h3>{{ propertyAgent.Title }}</h3>
            <br />
            <label>{{ propertyAgent.Email }}</label>
            <label>{{ propertyAgent.Phone }}</label>
            <label
              ><a v-if="propertyAgent.Mobile != ''" :href="getWANumber(propertyAgent.Mobile)">{{ propertyAgent.Mobile }}</a>
            </label>
          </div>
          <div class="agent-profile-container" :style="{ backgroundImage: 'url(' + propertyAgent.PhotoURL + ')' }"></div>
        </div>
      </div>
    </div>
    <!--
    <div class="page-home-content right">
      <h2 class="page-home-heading">Similar projects</h2>
      <div class="page-horizontal-scroll">
        <div class="page-property-item">
          <div class="page-property-item-bg"></div>
          <div class="page-property-item-text">Beckerich</div>
        </div>
        <div class="page-property-item">
          <div class="page-property-item-bg"></div>
          <div class="page-property-item-text">Beckerich</div>
        </div>
        <div class="page-property-item">
          <div class="page-property-item-bg"></div>
          <div class="page-property-item-text">Beckerich</div>
        </div>
        <div class="page-property-item">
          <div class="page-property-item-bg"></div>
          <div class="page-property-item-text">Beckerich</div>
        </div>
        <div class="page-property-item">
          <div class="page-property-item-bg"></div>
          <div class="page-property-item-text">Beckerich</div>
        </div>
        <div class="page-property-item">
          <div class="page-property-item-bg"></div>
          <div class="page-property-item-text">Beckerich</div>
        </div>
      </div>
    </div>

    -->

    <Footer></Footer>
  </div>
</template>

<script src="./js/PropertyBuilder.js"></script>

<style>
@import "./css/PropertyBuilder.css";
@import "./css/PropertyBuilder-mobile.css";
</style>
