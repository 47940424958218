<template>
  <div class="project-list-container">
    <div class="project-year-selector">
      <ul>
        <li>
          <div class="project-year selected">
            <div class="project-year-shape"></div>
            <h3 class="project-year-text">2022</h3>
          </div>
        </li>
        <li>
          <div class="project-year">
            <div class="project-year-shape"></div>
            <h3 class="project-year-text">2021</h3>
          </div>
        </li>
        <li>
          <div class="project-year">
            <div class="project-year-shape"></div>
            <h3 class="project-year-text">2020</h3>
          </div>
        </li>
        <li>
          <div class="project-year">
            <div class="project-year-shape"></div>
            <h3 class="project-year-text">2019</h3>
          </div>
        </li>
        <li>
          <div class="project-year">
            <div class="project-year-shape"></div>
            <h3 class="project-year-text">2018</h3>
          </div>
        </li>
      </ul>
    </div>

    <div class="project-list-inner">
      <h2 class="project-list-title">{{ title }}</h2>

      <div class="project-item-container" v-for="project in projects" :key="project">
        <div class="project-item">
          <h3 class="project-date">
            {{ project.attributes.Date.substring(0, 4) }}
          </h3>
          <a :href="project.attributes.LinkHREF"><img class="project-cover" :src="project.attributes.CoverUrl" /></a>
        </div>
        <div class="project-item">
          <h3 class="project-title">{{ project.attributes.Name }}</h3>
          <vue-markdown class="project-description" :source="project.attributes.RichText" />
          <a :href="project.attributes.LinkHREF"
            ><button class="project-button">{{ readmore }}</button></a
          >
          <a :href="project.attributes.LinkDocuments"><button class="project-button" v-if="project.attributes.Documents.data != null">Documents</button></a>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";

import VueMarkdown from "vue-markdown-render";

import Footer from "@/components/co-footer/co-footer.vue";
import { getLocaleElement } from "@/lib/locales.js";

export default {
  data() {
    return {
      title: "",
      readmore: "",
      projects: [],
    };
  },
  components: {
    VueMarkdown,
    Footer,
  },
  async mounted() {
    this.title = await getLocaleElement("lang-page-projects");
    this.readmore = await getLocaleElement("lang-page-projects-readmore");

    let locale = localStorage.getItem("immeck_lang");

    let query = "/api/projects-page";
    query = "/api/projects?populate=*&sort=Date:desc" + "&locale=" + locale;
    axios.get(process.env.VUE_APP_CMS_DOMAIN + query).then((response) => {
      let data = response.data.data;
      if (data.length != 0) {
        for (var i = 0; i < data.length; i++) {
          data[i].attributes.CoverUrl = process.env.VUE_APP_CMS_DOMAIN + "/" + data[i].attributes.Cover.data.attributes.url.substring(1);

          data[i].attributes.LinkHREF = "/projects/" + data[i].attributes.ProjectUID;

          data[i].attributes.LinkDocuments = "/projects/" + data[i].attributes.ProjectUID + "/documents";
        }
        this.projects = data;
      }
    });
  },
};
</script>

<style>
.project-year-selector {
  position: sticky;

  width: 8vw;

  margin: 0 0 0 0;
  top: 50%;
  transform: translateY(-50%);
}

.project-year-selector ul {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 -1.5vw;
  padding: 0;
}

.project-year-selector ul li {
  list-style-type: none;
  margin: 0;
  padding: 0.5vh 0 0.5vh 0;
}

.project-year {
  display: flex;
  flex-direction: row;
}

.project-year div {
  margin: 0 2vw 0 0;
  padding: 0;

  height: 2vh;
  width: 4vw;

  background-color: #ccc;

  transform: skewX(40deg);
  transition: all 200ms;
}

.project-year h3 {
  margin: 0;
  padding: 0;

  font-weight: 500;
}

.project-year.selected div {
  width: 5vw;
  background-color: #79232f;
}

.project-year:hover {
  cursor: pointer;
}

.project-year:hover div {
  width: 5vw;
  background-color: #666;
}

.project-list-title {
  color: #79232f;

  font-size: 8em;
  text-align: left;

  font-weight: 500;

  margin: 15vh 0 0 0;
  padding: 0;
}

.project-date {
  font-size: 2em;
  color: #79232f;
}

.project-list-inner {
  padding: 0 10vw 10vh 10vw;

  position: relative;
}

.project-item-container {
  width: 100%;

  display: flex;
  flex-wrap: wrap;

  align-items: center;
}

.project-item {
  flex-basis: calc(50% - 20px);
  padding: 10px;

  text-align: left;
}

.project-cover {
  width: 100%;
}

.project-button {
  background-color: #79232f;
  color: white;
  padding: 1em;
  border: none;
  margin-right: 1em;
  font-weight: 500;

  border-radius: 8px;
}

.project-button:hover {
  cursor: pointer;
}

.project-year-selector {
  display: none;
}

@media only screen and (max-width: 600px) {
  .project-year-selector {
    display: none;
  }

  .project-list-title {
    font-size: 22pt !important;
    margin: 15vh 0 5vh 0 !important;
  }

  .project-item-container {
    flex-direction: column !important;
  }
}
</style>
