<template>
  <div class="contact-team-container">
    <div class="contact-team-flex-container">
      <div class="contact-team-flex" v-for="pa in propertyAgents" :key="pa">
        <div class="contact-team-profile" :style="{ backgroundImage: `url(${pa.PhotoURL})` }"></div>
        <div class="contact-team-info">
          <h2 class="contact-team-info-main space">
            {{ pa.Firstname + " " + pa.Lastname }}
          </h2>
          <h3 class="contact-team-info-sub">{{ pa.Title }}</h3>
          <h3 class="contact-team-info-sub space">E-mail : {{ pa.Email }}</h3>
          <h3 class="contact-team-info-sub">Tél. : {{ pa.Phone }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "pb-pagecontactteam",
  data() {
    return {
      propertyAgents: [],
    };
  },
  mounted() {
    let locale = localStorage.getItem("immeck_lang");

    axios.get(process.env.VUE_APP_CMS_DOMAIN + "/api/agents/?populate=*&sort=AgentId:desc&locale=" + locale).then((response) => {
      let res = response.data.data;

      for (let i = 0; i < res.length; i++) {
        const element = res[i];

        let propertyAgent = element.attributes;

        propertyAgent.PhotoURL = process.env.VUE_APP_CMS_DOMAIN + element.attributes.Photo.data.attributes.url;

        this.propertyAgents.push(propertyAgent);
      }

      console.log(res);
    });
  },
};
</script>

<style>
.contact-team-flex-container {
  width: 80vw;
  margin: 0 0 0 10vw;

  display: flex;
}

.contact-team-flex {
  flex: 1;
  margin: 0.5vw;
}

.contact-team-profile {
  height: 20vw;
  background-size: cover;
}

.contact-team-info {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
}

.contact-team-info .space {
  padding: 2vh 0 0 0;
}

.contact-team-info-main {
  margin: 0;
  padding: 0;

  font-family: Roboto, sans-serif;
  font-size: 18pt;
  color: #555;
}

.contact-team-info-sub {
  margin: 0;
  padding: 0;

  font-family: Roboto, sans-serif;
  font-size: 12pt;
  font-weight: 400;
  color: #555;

  text-align: left;
}

@media only screen and (max-width: 600px) {
  .contact-team-flex-container {
    flex-direction: column;
  }

  .contact-team-profile {
    height: 40vh;
  }

  .contact-team-flex {
    padding: 10vh 0 10vh 0;
  }

  .contact-form-info-container {
    flex-direction: column;
  }

  .contact-form-info-flex {
    padding: 2vh 0 2vh 0;
  }

  .contact-form-outer {
    flex-direction: column;
  }

  .contact-info-title {
    text-align: left;
  }
}
</style>
