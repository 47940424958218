<template>
  <div class="imagify-pagebuilder">
    <div
      class="project-builder-cover"
      v-if="projectoutput.attributes"
      :style="{
        backgroundImage: 'url(' + coverImage + ')',
      }"
    ></div>

    <h2 class="project-builder-title" v-if="projectoutput.attributes != null">
      {{ projectoutput.attributes.Name }}
    </h2>

    <vue-markdown class="project-builder-description" :source="projectoutput.attributes.RichText" v-if="projectoutput.attributes != null" />

    <div class="project-builder-gallery-container">
      <div class="project-builder-gallery-item" v-for="gItem in galleryItems" :key="gItem" :style="{ backgroundImage: 'url(' + gItem + ')' }"></div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";
import VueMarkdown from "vue-markdown-render";

import Footer from "@/components/co-footer/co-footer.vue";

export default {
  data() {
    return {
      pageName: null,
      setupComplete: false,
      componentList: [],
      projectoutput: {},
      galleryItems: [],
      coverImage: "",
    };
  },
  components: {
    VueMarkdown,
    Footer,
  },
  methods: {
    setup: function () {
      // Get Page Name
      if (!this.setupComplete) {
        this.pageName = this.$route.params.project;

        let locale = localStorage.getItem("immeck_lang");

        let query = "/api/projects?filters[ProjectUID]=" + this.pageName + "&locale=" + locale + "&populate=deep";

        axios.get(process.env.VUE_APP_CMS_DOMAIN + query).then((response) => {
          let data = response.data.data;
          if (data.length == 0) {
            // Page not found
            let query = "/api/pages?filters[PageUID]=404&populate=deep";
            axios.get(process.env.VUE_APP_CMS_DOMAIN + query).then((response) => {
              let data = response.data.data;
              if (data.length == 0) {
                console.log("404 Page not found");
              } else {
                this.buildPage(data);
              }
            });
          } else {
            // Build Project
            this.projectoutput = data[0];

            // Build Gallery
            for (var i = 0; i < data[0].attributes.Gallery.data.length; i++) {
              var galleryData = data[0].attributes.Gallery.data[i];
              this.galleryItems.push(process.env.VUE_APP_CMS_DOMAIN + "/" + galleryData.attributes.url.substring(1));
            }

            // Cover Image
            this.coverImage = process.env.VUE_APP_CMS_DOMAIN + "/" + this.projectoutput.attributes.Cover.data.attributes.url.substring(1);
          }
        });

        this.setupComplete = true;
      }
    },
  },
  mounted() {
    this.setup();
  },
  updated() {
    this.setup();
  },
};
</script>

<style>
.project-builder-gallery-container {
  width: 100vw;
  height: 50vh;

  display: flex;
  overflow-x: scroll;
  flex-wrap: nowrap;
}

.project-builder-gallery-item {
  height: 100%;
  width: 50vw;
  flex-shrink: 0;

  background-position: center;
  background-size: cover;
}

.project-builder-title {
  padding: 10vh 0 0 0;
}

.project-builder-description {
  padding: 5vh 0 20vh 0;
  margin: 0 10vw 0 10vw;
}

.project-builder-cover {
  width: 100vw;
  height: 70vh;

  background-size: cover;
  background-position: center;
}
</style>
