<template>
  <div class="pb-spacer" :style="{ height: vh }"></div>
</template>

<script>
export default {
  name: "pbpagetitle",
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      vh: "",
    };
  },
  mounted() {
    if (this.schema.VH != null) this.vh = this.schema.VH + "vh";
  },
};
</script>

<style>
.pb-spacer {
  width: 100%;
  background-color: transparent;
}

@media only screen and (max-width: 600px) {
  .pb-spacer {
    height: 0 !important;
  }
}
</style>
