<template>
  <div class="estimation-form-container">
    <div class="estimation-form-flex">
      <div class="estimation-form-shape"></div>
      <h3 class="estimation-form-text">{{ text }}</h3>
    </div>
    <div class="estimation-form-flex">
      <div class="estimation-form-inner">
        <div class="estimation-form-element">
          <label class="estimation-form-label">Superficie</label>
          <input type="text" class="estimation-form-input" />
        </div>
        <div class="estimation-form-element">
          <label class="estimation-form-label">Ville</label>
          <input type="text" class="estimation-form-input" />
        </div>
        <div class="estimation-form-element">
          <label class="estimation-form-label">Terrain</label>
          <input type="text" class="estimation-form-input" />
        </div>
        <div class="estimation-form-element">
          <label class="estimation-form-label">Chambres</label>
          <input type="text" class="estimation-form-input" />
        </div>
        <div class="estimation-form-element">
          <label class="estimation-form-label">Parking</label>
          <input type="text" class="estimation-form-input" />
        </div>
        <div class="estimation-form-element">
          <label class="estimation-form-label">Orientation</label>
          <input type="text" class="estimation-form-input" />
        </div>
        <div class="estimation-form-element">
          <label class="estimation-form-label">Chauffage</label>
          <input type="text" class="estimation-form-input" />
        </div>
        <div class="estimation-form-element">
          <label class="estimation-form-label">Cave</label>
          <input type="text" class="estimation-form-input" />
        </div>
        <div class="estimation-form-element">
          <div class="estimation-form-button-bg">
            <input type="button" class="estimation-form-input-button" value="Estimer" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      text: "Undefined",
    };
  },
  mounted() {
    this.text = this.schema.Text;
  },
};
</script>

<style>
.estimation-form-container {
  display: flex;
  padding: 10vh 10vw 10vh 10vw;
}

.estimation-form-flex {
  flex: 1;

  position: relative;
}

.estimation-form-inner {
  display: flex;
  flex-direction: column;

  align-content: flex-end;
  align-items: flex-end;
}

.estimation-form-input {
  height: 2.5em;
  width: 25vw;
}

.estimation-form-label {
  font-size: 18pt;
  font-weight: 500;
  color: #79232f;
  padding-right: 2em;
}

.estimation-form-element {
  padding-bottom: 1em;
}

.estimation-form-button-bg {
  background-color: #79232f;
  padding: 2em 4em 2em 4em;

  transform: skewX(40deg);
}

.estimation-form-input-button {
  transform: skewX(-40deg);

  background-color: transparent;
  border: none;

  color: white;

  font-size: 18pt;
  font-weight: 500;
}

.estimation-form-text {
  margin: 0;
  padding: 0;

  font-size: 20pt;
  width: 20vw;

  text-align: left;

  color: rgb(99, 99, 99);
}

.estimation-form-shape {
  position: absolute;

  top: 0;
  left: -15vw;

  height: 8vh;
  width: 10vw;

  background-color: #ccc;

  transform: skewX(40deg);
}

@media only screen and (max-width: 600px) {
  .estimation-form-container {
    flex-direction: column;
  }

  .estimation-form-shape {
    display: none;
  }

  .estimation-form-text {
    width: 80vw;
    margin: 0 0 10vh 0;
  }

  .estimation-form-button-bg {
    padding: 1em 2em 1em 2em;
  }
}
</style>
