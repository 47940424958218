import { getLocaleElement } from "@/lib/locales.js";

export default {
  name: "Footer",
  data() {
    return {
      langNavigationAccueil: "",
      langNavigationContact: "",
      langNavigationLocations: "",
      langNavigationProjets: "",
      langNavigationTitle: "",
      langNavigationVentes: "",
      langNavigationOO: "",
      langNavigationOO1: "",
      langNavigationOO2: "",
      langNavigationlegal: "",
      langNavigationprivacy: "",
    };
  },
  async mounted() {
    this.langNavigationAccueil = await getLocaleElement("lang-navigation-accueil");
    this.langNavigationContact = await getLocaleElement("lang-navigation-contact");
    this.langNavigationLocations = await getLocaleElement("lang-navigation-locations");
    this.langNavigationProjets = await getLocaleElement("lang-navigation-projets");
    this.langNavigationTitle = await getLocaleElement("lang-navigation-title");
    this.langNavigationVentes = await getLocaleElement("lang-navigation-ventes");

    this.langNavigationOO = await getLocaleElement("lang-navigation-oo");
    this.langNavigationOO1 = await getLocaleElement("lang-navigation-oo-1");
    this.langNavigationOO2 = await getLocaleElement("lang-navigation-oo-2");

    this.langNavigationlegal = await getLocaleElement("lang-navigation-mentionslegal");
    this.langNavigationprivacy = await getLocaleElement("lang-navigation-protection");
  },
};
