<template>
  <div class="properties-list-container">
    <div class="properties-filter-container"></div>
    <div class="properties-filter-background">
      <div class="properties-filter-mainselect">
        <div
          class="properties-filter-mainselect-item"
          :class="{ select: filterBuy }"
          @click="
            filterBuy = true;
            filterRent = false;
          "
        >
          {{ langPropertiesAcheter }}
        </div>
        <div
          class="properties-filter-mainselect-item"
          :class="{ select: filterRent }"
          @click="
            filterBuy = false;
            filterRent = true;
          "
        >
          {{ langPropertiesLouer }}
        </div>
      </div>

      <Teleport to="body">
        <div class="trigger-container" @click="closeDropdowns"></div>
      </Teleport>

      <div class="properties-filter-item-container">
        <MultiDropdown
          class="properties-filter-item"
          :data="cities"
          :value="langFilterLocalities"
          :valueP="langFilterLocalities"
          @dataarray="emitCityChanged"
          ref="mdd1"
          @click="closeDropdownsExcept('mdd1')"
        >
        </MultiDropdown>

        <MultiDropdown
          class="properties-filter-item"
          :data="ptypes"
          :value="langFilterTypes"
          :valueP="langFilterTypes"
          @dataarray="emitPTypeChanged"
          ref="mdd2"
          @click="closeDropdownsExcept('mdd2')"
        ></MultiDropdown>

        <MultiDropdown
          class="properties-filter-item"
          :data="chambres"
          :value="langFilterBedrooms"
          :valueP="langFilterBedrooms"
          @dataarray="emitChambresChanged"
          ref="mdd3"
          @click="closeDropdownsExcept('mdd3')"
          v-if="isLocale('fr-FR')"
        ></MultiDropdown>

        <MultiDropdown
          class="properties-filter-item"
          :data="chambresEN"
          :value="langFilterBedrooms"
          :valueP="langFilterBedrooms"
          @dataarray="emitChambresChanged"
          ref="mdd3"
          @click="closeDropdownsExcept('mdd3')"
          v-if="isLocale('en')"
        ></MultiDropdown>
      </div>

      <div class="properties-slider-container">
        <div class="properties-slider-inner">
          <label class="properties-label-text">{{ langFilterSurface }}</label>

          <Slider v-model="selectedSurface" :min="valuesurface[0]" :max="valuesurface[1]" :step="5" :format="formatSurface" class="slider-red"></Slider>
          <div class="properties-slider-spacer"></div>

          <label class="properties-label-text">{{ langFilterPrice }}</label>

          <Slider v-model="selectedValueSell" v-if="filterBuy" :min="valueSell[0]" :max="valueSell[1]" :step="1" :format="formatPriceSell" class="slider-red"></Slider>
          <Slider v-model="selectedValueRent" v-if="filterRent" :min="valueRent[0]" :max="valueRent[1]" :step="1" :format="formatPriceRent" class="slider-red"></Slider>
        </div>
      </div>
    </div>

    <div class="property-list-pagetitle">{{ langPropertyListTitle }}</div>
    <div class="property-list-pagetext">{{ langPropertyListText }}</div>

    <div class="properties-list-inner">
      <PropertyItem
        v-for="property in filteredproperties"
        :key="property.id"
        @click="openProperty(property.id)"
        :id="property.id"
        :Location="property.attributes.Location"
        :Sell="property.attributes.Sell"
        :Nature="property.attributes.Nature"
        :Price="property.attributes.Price"
        :Surface="property.attributes.Surface"
        :Bedrooms="property.attributes.Bedrooms"
        :Bathrooms="property.attributes.Bathrooms"
        :Parking="property.attributes.Parking"
        :SurfaceArea="property.attributes.SurfaceArea"
        :PropertyPhotoUrl="property.attributes.Photo[0].PropertyPhotoUrl"
      ></PropertyItem>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Slider from "@vueform/slider";
import { getLocaleElement } from "@/lib/locales.js";
import Footer from "@/components/co-footer/co-footer.vue";
import PropertyItem from "@/components/co-property-item/co-property-item.vue";

import MultiDropdown from "@/components/MultiDropdown.vue";
const axios = require("axios");

export default {
  name: "PropertyList",
  components: {
    Footer,
    MultiDropdown,
    Slider,
    PropertyItem,
  },
  data: () => ({
    buyrentswitch: false,
    allproperties: [],
    allcities: [],
    allpropertytypes: [],
    allsurfaces: [],
    filteredproperties: [],
    filteredpropertiesall: [],
    selectedValueSell: [0, 50],
    selectedValueRent: [0, 24],
    selectedSurface: [0, 300],
    valueSell: [0, 50],
    valueRent: [0, 24],
    format: {
      prefix: "€",
      decimals: 0,
      thousand: ".",
    },
    formatSurface: {
      postfix: " m²",
      decimals: 0,
    },
    formatPriceSell: function (value) {
      if (value <= 20) {
        return (
          Math.round(value * 50000)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
        );
      } else if (value < 50) {
        return (
          Math.round(1000000 + (value - 20) * 100000)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
        );
      } else {
        return "4.000.000 €+";
      }
    },
    formatPriceRent: function (value) {
      if (value <= 10) {
        return (
          Math.round(value * 100)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
        );
      } else if (value < 20) {
        return (
          Math.round(1000 + (value - 10) * 200)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
        );
      } else if (value < 24) {
        return (
          Math.round(3000 + (value - 20) * 500)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
        );
      } else {
        return "5.000 €+";
      }
    },
    valuesurface: [0, 300],
    filterBuy: true,
    filterRent: false,
    filterCity: [],
    filterPType: [],
    filterChambres: [],
    filterControlType: "acheter",
    cities: [],
    ptypes: [],
    surfaces: [],
    chambres: ["1 chambre", "2 chambres", "3 chambres", "4+ chambres"],
    chambresEN: ["1 bedroom", "2 bedrooms", "3 bedrooms", "4+ bedrooms"],
    type: ["Acheter", "Louer"],
    langPropertiesAcheter: "",
    langPropertiesLouer: "",
    langPropertyListTitle: "",
    langPropertyListText: "",

    langFilterLocalities: "",
    langFilterTypes: "",
    langFilterBedrooms: "",
    langFilterSurface: "",
    langFilterPrice: "",
  }),
  async mounted() {
    this.langPropertyListTitle = await getLocaleElement("lang-propertylist-title");
    this.langPropertyListText = await getLocaleElement("lang-propertylist-text");
    this.langPropertiesAcheter = await getLocaleElement("lang-properties-acheter");
    this.langPropertiesLouer = await getLocaleElement("lang-properties-louer");

    this.langFilterLocalities = await getLocaleElement("lang-filter-localities");
    this.langFilterTypes = await getLocaleElement("lang-filter-types");
    this.langFilterBedrooms = await getLocaleElement("lang-filter-bedrooms");
    this.langFilterSurface = await getLocaleElement("lang-filter-surface");
    this.langFilterPrice = await getLocaleElement("lang-filter-price");

    axios.get(process.env.VUE_APP_CMS_DOMAIN + "/api/properties?populate=*&sort=Price:asc").then((response) => {
      let res = response.data.data;

      this.allproperties = res;
      this.filteredproperties = res;

      for (var i = 0; i < res.length; i++) {
        console.log(res[i]);

        var city = res[i].attributes.Location;
        var citytype = res[i].attributes.Sell;
        var surface = res[i].attributes.Surface;

        this.cities.push(city);
        this.allcities.push([city, citytype]);
        this.allsurfaces.push(parseInt(surface));

        // Types
        var propertytype = res[i].attributes.Nature;
        this.ptypes.push(propertytype);
        this.allpropertytypes.push(propertytype);
      }

      let uniquecities = this.cities.filter((element, index) => {
        return this.cities.indexOf(element) === index;
      });
      this.cities = uniquecities.sort();

      let uniquetypes = this.ptypes.filter((element, index) => {
        return this.ptypes.indexOf(element) === index;
      });
      this.ptypes = uniquetypes.sort();

      // Force translation
      if (localStorage.getItem("immeck_lang") == "en") {
        let fptTrans = [];
        for (var j = 0; j < this.ptypes.length; j++) {
          switch (this.ptypes[j]) {
            case "Appartement":
              fptTrans.push("Apartment");
              break;
            case "Maison":
              fptTrans.push("House");
              break;
            case "Commerce":
              fptTrans.push("Commercial");
              break;
            case "Terrain":
              fptTrans.push("Terrain");
              break;
            case "Garage":
              fptTrans.push("Garage");
              break;
            default:
              fptTrans.push(this.ptypes[j]);
          }
        }

        this.ptypes = fptTrans;
        console.log(fptTrans);
      }

      if (this.$route.query.type) {
        console.log(this.$route.query.type); // outputs 'yay'
        if (this.$route.query.type == "louer") {
          this.filterBuy = false;
          this.filterRent = true;
        }
      }
    });
  },
  methods: {
    isLocale(locale) {
      let lcle = localStorage.getItem("immeck_lang");
      if (locale == lcle) {
        return true;
      } else {
        return false;
      }
    },
    closeDropdowns() {
      this.$refs.mdd1.close();
      this.$refs.mdd2.close();
      this.$refs.mdd3.close();
    },
    closeDropdownsExcept(mdd) {
      if (mdd == "mdd1") {
        this.$refs.mdd2.close();
        this.$refs.mdd3.close();
      } else if (mdd == "mdd2") {
        this.$refs.mdd1.close();
        this.$refs.mdd3.close();
      } else if (mdd == "mdd3") {
        this.$refs.mdd1.close();
        this.$refs.mdd2.close();
      }
    },
    priceRange(value) {
      return value * 10;
    },
    generateSubtitle(type, nature) {
      console.log(localStorage.getItem("immeck_lang"));
      if (localStorage.getItem("immeck_lang") == "en") {
        let ntr = "";
        switch (nature) {
          case "Appartement":
            ntr = "Apartment";
            break;
          case "Maison":
            ntr = "House";
            break;
          case "Commerce":
            ntr = "Commercial";
            break;
          case "Terrain":
            ntr = "Terrain";
            break;
          case "Garage":
            ntr = "Garage";
            break;
          default:
            ntr = nature;
        }

        if (type == true) {
          return ntr + " for sale";
        } else {
          return ntr + " for rent";
        }
      } else {
        if (type == true) {
          return nature + " à vendre";
        } else {
          return nature + " à louer";
        }
      }
    },
    getPhoto(id, url) {
      return {
        backgroundImage: "url(" + url + ")",
      };
    },
    launchFilter() {
      this.filterArray();
    },
    openProperty(id) {
      window.location.href = "/properties/" + id;
    },
    resetFilter() {
      console.log("reset");
      // this.filterCity.splice(0, this.filterCity.length);

      let cFilteredCities = [];
      for (var i = 0; i < this.allcities.length; i++) {
        if (this.allcities[i][1] == true && this.filterBuy == true) {
          cFilteredCities.push(this.allcities[i][0]);
        }
        if (this.allcities[i][1] == false && this.filterRent == true) {
          cFilteredCities.push(this.allcities[i][0]);
        }
        if (this.filterRent == false && this.filterBuy == false) {
          cFilteredCities.push(this.allcities[i][0]);
        }
      }

      let uniquecities = cFilteredCities.filter((element, index) => {
        return cFilteredCities.indexOf(element) === index;
      });
      this.cities = uniquecities.sort();

      this.filterArray();
    },
    filterArray() {
      let fp = this.allproperties;

      if (this.filterBuy == true && this.filterRent == true) {
        fp = fp.filter((obj) => obj.attributes.Sell == true || obj.attributes.Sell == false);
      } else {
        if (this.filterBuy == true) {
          fp = fp.filter((obj) => obj.attributes.Sell == true);
        }

        if (this.filterRent == true) {
          fp = fp.filter((obj) => obj.attributes.Sell == false);
        }
      }

      // Filter Location
      if (this.filterCity.length > 0) {
        fp = fp.filter((obj) => this.filterCity.includes(obj.attributes.Location));
      }

      // Filter PType
      if (this.filterPType.length > 0) {
        fp = fp.filter((obj) => this.filterPType.includes(obj.attributes.Nature));
      }

      // Filter chambres
      if (this.filterChambres.length > 0) {
        let fpn = [];
        for (let i = 0; i < fp.length; i++) {
          const element = fp[i];

          if (element.attributes.Bedrooms > 3 && (this.filterChambres.includes("4+ chambres") || this.filterChambres.includes("4+ bedrooms"))) {
            fpn.push(element);
          } else {
            if (element.attributes.Bedrooms == 3 && (this.filterChambres.includes("3 chambres") || this.filterChambres.includes("3 bedrooms"))) {
              fpn.push(element);
            } else {
              if (element.attributes.Bedrooms == 2 && (this.filterChambres.includes("2 chambres") || this.filterChambres.includes("2 bedrooms"))) {
                fpn.push(element);
              } else {
                if (element.attributes.Bedrooms == 1 && (this.filterChambres.includes("1 chambre") || this.filterChambres.includes("1 bedroom"))) {
                  fpn.push(element);
                }
              }
            }
          }
        }

        fp = fpn;
      }

      // Check high and low price
      /*
      if (fp.length > 0) {
        this.value = [parseInt(fp[0].prix_budget), parseInt(fp[0].prix_budget)];
      }

      for (var i = 0; i < fp.length; i++) {
        let currentPropertyPrice = fp[i].prix_budget;

        if (parseInt(currentPropertyPrice) > this.value[1]) {
          this.value[1] = parseInt(currentPropertyPrice);
        }

        if (parseInt(currentPropertyPrice) < this.value[0]) {
          this.value[0] = parseInt(currentPropertyPrice);
        }
        this.selectedValue = this.value;
      }
      */

      this.filteredproperties = fp;
      this.filteredpropertiesall = fp;

      let res = this.execSurfaceSlider(fp);

      if (this.filterBuy) {
        this.execPriceSliderSell(res);
      }

      if (!this.filterBuy) {
        this.execPriceSliderRent(res);
      }
    },
    convertPrice(price) {
      let priceConvert = 0;
      if (price <= 1000000) {
        priceConvert = price / 50000;
      } else if (price <= 5000000) {
        priceConvert = (price - 1000000) / 1000000 + 20;
      } else {
        priceConvert = 50;
      }

      console.log("Price converted: " + price + " to: " + priceConvert);
      return priceConvert;
    },
    execPriceRent: function (value) {
      if (value <= 10) {
        return Math.round(value * 100);
      } else if (value < 20) {
        return Math.round(1000 + (value - 10) * 200);
      } else if (value < 24) {
        return Math.round(3000 + (value - 20) * 500);
      } else {
        return 5000;
      }
    },
    execPriceSell: function (value) {
      if (value <= 20) {
        return Math.round(value * 50000);
      } else if (value < 50) {
        return Math.round(1000000 + (value - 20) * 100000);
      } else {
        return 4000000;
      }
    },
    execPriceSliderSell(fp) {
      console.log("Ajust price");
      let currentFilter = [];

      for (var i = 0; i < fp.length; i++) {
        let currentPropertyPrice = fp[i].attributes.Price;

        if (this.execPriceSell(this.selectedValueSell[0]) <= currentPropertyPrice && this.execPriceSell(this.selectedValueSell[1]) >= currentPropertyPrice) {
          currentFilter.push(fp[i]);
        } else {
          if (this.selectedValueSell[1] == 50) {
            if (currentPropertyPrice > this.execPriceSell(this.selectedValueSell[1])) {
              currentFilter.push(fp[i]);
            }
          }
        }
      }
      this.filteredproperties = currentFilter;
      return currentFilter;
    },
    execPriceSliderRent(fp) {
      console.log("Ajust price");
      let currentFilter = [];

      for (var i = 0; i < fp.length; i++) {
        let currentPropertyPrice = fp[i].attributes.Price;

        if (this.execPriceRent(this.selectedValueRent[0]) <= currentPropertyPrice && this.execPriceRent(this.selectedValueRent[1]) >= currentPropertyPrice) {
          currentFilter.push(fp[i]);
        } else {
          if (currentPropertyPrice > this.execPriceRent(this.valueRent[1]) && this.selectedValueRent[1] == this.valueRent[1]) {
            currentFilter.push(fp[i]);
          }
        }
      }
      this.filteredproperties = currentFilter;
      return currentFilter;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    splitDescription(text) {
      var regexp = /<br>/g;
      var key,
        positions = [];
      while ((key = regexp.exec(text)) != null) {
        positions.push(key.index);
      }

      var trimmedString;
      if (positions.length > 2) {
        trimmedString = text.substring(0, positions[3]);
        return trimmedString;
      } else {
        trimmedString = text.substring(0, 200);
        return trimmedString + "...";
      }
    },

    emitTypeChanged(type) {
      console.log("type changed: " + type);
      for (var i = 0; i < type.length; i++) {
        if (type[i].entry == "Acheter") {
          if (type[i].active == true) {
            this.filterBuy = true;
          } else {
            this.filterBuy = false;
          }
        } else {
          if (type[i].active == true) {
            this.filterRent = true;
          } else {
            this.filterRent = false;
          }
        }
      }
    },
    emitCityChanged(city) {
      if (city.length == 0) return;
      console.log("city changed");

      this.filterCity = [];
      let fc = [];
      let fpt = [];
      for (var i = 0; i < city.length; i++) {
        if (city[i].active == true) {
          fc.push(city[i].entry);

          // Adjust ptype
          for (var j = 0; j < this.allproperties.length; j++) {
            if (this.allproperties[j].attributes.Location == city[i].entry) {
              fpt.push(this.allproperties[j].attributes.Nature);
            }
          }
        }
      }

      if (fpt.length == 0) {
        for (var x = 0; x < this.allproperties.length; x++) {
          fpt.push(this.allproperties[x].attributes.Nature);
        }
      }

      let uniquept = fpt.filter((element, index) => {
        return fpt.indexOf(element) === index;
      });

      if (uniquept.length == 0) {
        this.ptypes = uniquept.sort();
        for (var j2 = 0; j2 < this.allproperties.length; j2++) {
          fpt.push(this.allproperties[j2].attributes.Nature);
        }

        let uniquept = fpt.filter((element, index) => {
          return fpt.indexOf(element) === index;
        });

        this.ptypes = uniquept.sort();
      } else {
        this.ptypes = uniquept.sort();
      }

      // Force translation
      if (localStorage.getItem("immeck_lang") == "en") {
        let fptTrans = [];
        for (var a = 0; a < this.ptypes.length; a++) {
          switch (this.ptypes[a]) {
            case "Appartement":
              fptTrans.push("Apartment");
              break;
            case "Maison":
              fptTrans.push("House");
              break;
            case "Commerce":
              fptTrans.push("Commercial");
              break;
            case "Terrain":
              fptTrans.push("Terrain");
              break;
            case "Garage":
              fptTrans.push("Garage");
              break;
            default:
              fptTrans.push(this.ptypes[a]);
          }
        }

        this.ptypes = fptTrans;
        console.log(fptTrans);
      }

      this.filterCity = fc;
    },
    emitPTypeChanged(ptype) {
      console.log("pytype changed");
      this.filterPType = [];
      let fpt = [];
      for (var i = 0; i < ptype.length; i++) {
        if (ptype[i].active == true) {
          if (localStorage.getItem("immeck_lang") == "en") {
            // Force translation
            switch (ptype[i].entry) {
              case "Apartment":
                fpt.push("Appartement");
                break;
              case "House":
                fpt.push("Maison");
                break;
              case "Commercial":
                fpt.push("Commerce");
                break;
              case "Terrain":
                fpt.push("Terrain");
                break;
              case "Garage":
                fpt.push("Garage");
                break;
              default:
                fpt.push(ptype[i].entry);
            }
          } else {
            fpt.push(ptype[i].entry);
          }
        }
      }

      this.filterPType = fpt;
    },
    emitChambresChanged(cmbrs) {
      console.log("chambres changed");
      this.filterChambres = [];
      let fcmbrs = [];
      for (var i = 0; i < cmbrs.length; i++) {
        if (cmbrs[i].active == true) {
          fcmbrs.push(cmbrs[i].entry);
        }
      }
      this.filterChambres = fcmbrs;
    },
    execSurfaceSlider(fp) {
      console.log("Ajust surface");
      let currentFilter = [];

      for (var i = 0; i < fp.length; i++) {
        let currentPropertySurface = fp[i].attributes.Surface;

        if (this.selectedSurface[0] <= currentPropertySurface && this.selectedSurface[1] >= currentPropertySurface) {
          currentFilter.push(fp[i]);
        } else {
          if (this.selectedSurface[1] == this.valuesurface[1]) {
            if (currentPropertySurface > this.selectedSurface[1]) {
              currentFilter.push(fp[i]);
            }
          }
        }
      }
      this.filteredproperties = currentFilter;
      return currentFilter;
    },
  },
  watch: {
    // whenever question changes, this function will run
    filterBuy: {
      handler: "resetFilter",
    },
    filterRent: {
      handler: "resetFilter",
    },
    filterCity: {
      handler: "launchFilter",
    },
    filterPType: {
      handler: "launchFilter",
    },
    filterChambres: {
      handler: "launchFilter",
    },
    filterControlType(newState) {
      if (newState == "acheter") {
        this.filterBuy = true;
        this.filterRent = false;
      } else {
        this.filterBuy = false;
        this.filterRent = true;
      }
    },
    selectedValue: {
      handler: "execPriceSlider",
    },
    selectedValueSell() {
      let fp = this.filteredpropertiesall;
      let res = this.execPriceSliderSell(fp);
      this.execSurfaceSlider(res);
    },
    selectedValueRent() {
      let fp = this.filteredpropertiesall;
      let res = this.execPriceSliderRent(fp);
      this.execSurfaceSlider(res);
    },
    selectedSurface() {
      let fp = this.filteredpropertiesall;
      let res = this.execSurfaceSlider(fp);
      if (this.filterBuy) {
        this.execPriceSliderSell(res);
      } else {
        this.execPriceSliderRent(res);
      }
    },
  },
};
</script>

<style>
.properties-list-inner {
  margin: -5vh 0 0 0;
  padding: 0 10vw 10vh 10vw;

  position: relative;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  display: grid;
  gap: 20px;
}

.properties-filter-container {
  height: 40vh;
  width: 100%;

  background-color: #eee;
  background-image: url("@/assets/photos/maison-beckerich-15.jpg");
  background-position: center;
  background-size: cover;

  padding: 0 0 10vh 0;

  display: flex;

  justify-content: center;
  align-items: flex-end;
}

.properties-filter-background {
  min-height: 25vh;
  width: 50vw;
  margin: 0 0 0 25vw;

  transform: translateY(-50%);

  background-color: #79232f;

  position: relative;
  z-index: 1;

  border-radius: 8px;

  box-shadow: 0 0 5vh 0 rgba(0, 0, 0, 0.15);
}

.properties-filter-item-container {
  display: flex;

  justify-content: center;
}

.properties-filter-item {
  height: 4.5vh;
  margin: 2vw 0 2vw 0;

  width: 30%;

  background-color: white;

  border: 1px solid #e3e3e3;
  border-right: none;

  padding: 0 0 0 0;

  font-family: "Roboto", sans-serif;
}

.properties-filter-item:first-child {
  margin: 2vw 0vw 2vw 0;
}

.properties-filter-item:last-child {
  margin: 2vw 0 2vw 0;
}

.slider-red {
  margin: 0 8vw 0 8vw;

  --slider-bg: #68202a;
  --slider-connect-bg: #eee;
  --slider-tooltip-bg: white;
  --slider-tooltip-color: #79232f;
  --slider-handle-ring-color: #79232f;
}

.properties-label-text {
  color: white;
}

.properties-slider-container {
  margin: 4vh 0 0 0;
  padding: 0 0 5vh 0;

  display: flex;
  flex-direction: column;

  align-content: center;
  justify-content: center;
}

.properties-slider-spacer {
  width: 100%;
  height: 4vh;
}

.properties-filter-mainselect {
  width: 100%;
  display: flex;

  background-color: #681b26;
  border-radius: 8px;
}

.properties-filter-mainselect-item {
  flex: 1;
  min-height: 6vh;

  display: flex;

  align-items: center;
  justify-content: center;

  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 12pt;

  color: white;
  position: relative;

  cursor: pointer;

  user-select: none;
  border-radius: 8px;
}

.properties-filter-mainselect-item.select {
  background-color: white;
  color: #681b26;
}

.properties-filter-mainselect-item.select::after {
  content: " ";
  position: absolute;
  bottom: -0.9vh;
  height: 0vh;
  width: 0vh;

  border-left: 1.2vh solid transparent;
  border-right: 1.2vh solid transparent;
  border-top: 1vh solid white;
}

.properties-advanced-search {
  position: fixed;
  bottom: 2vh;
  width: 80vw;
  height: 8vh;
  margin: 0 0 0 10vw;

  background-color: #681b26;
  text-align: center;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.properties-advanced-search h2 {
  color: white;
  margin: 0;
  padding: 0;

  font-size: 12pt;
  font-weight: 400;
}

.properties-advanced-search-blocker {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 14vh;

  background-color: #79232f;
}

.properties-advanced-search-blocker::after {
  content: "";

  position: absolute;
  left: 50vw;
  bottom: 0;
  transform: translate(-2vh, 1.9vh);

  border-top: 2vh solid #79232f;
  border-left: 2vh solid transparent;
  border-right: 2vh solid transparent;
}

.trigger-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.slider-origin:nth-child(2) .slider-tooltip-top {
  transform: translateX(calc(-100% - 20px));
  bottom: -5px;
}

.slider-origin:nth-child(3) .slider-tooltip-top {
  transform: translateX(calc(0% + 20px));
  bottom: -5px;
}

.slider-tooltip-top:before {
  content: none !important;
}

@media only screen and (max-width: 1000px) {
  .properties-list-inner {
    padding: 5vh 0 10vh 0;

    position: relative;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
    gap: 20px;
  }

  .properties-filter-background {
    width: 80vw;
    margin: -10vh 0 0 10vw;
  }

  .slider-red {
    margin: 0 20vw 0 10vw;
  }
}

.property-list-subtitle {
  margin-top: 15vh;
  color: white;
}

.property-list-pagetitle {
  font-family: "Roboto", sans-serif;
  font-size: 18pt;
  font-weight: 500;
  color: #555;

  transform: translateY(-12vh);
}

.property-list-pagetext {
  font-family: "Roboto", sans-serif;
  font-size: 12pt;
  font-weight: 400;
  color: #555;
  z-index: 0;
  transform: translateY(-12vh);

  width: 60vw;
  margin: 2vh 0 0 20vw;
}

@media only screen and (max-width: 600px) {
  .properties-list-inner {
    padding: 5vh 0 10vh 0;
    margin: 0 20px 0 20px;

    position: relative;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    display: grid;
    gap: 20px;
  }

  .properties-filter-background {
    width: 100vw;
    margin: -10vh 0 0 0vw;
    height: auto;

    display: flex;
    flex-direction: column;
    border-radius: 0;

    justify-content: center;
  }

  .properties-filter-item-container {
    margin: 0 0 2em 0;
    flex-direction: column;
  }

  .properties-filter-item {
    width: 90vw;
    margin: 1vh 0vw 1vh 5vw;
  }

  .properties-filter-item:first-child {
    margin: 4vh 0vw 1vh 5vw;
  }

  .properties-filter-item:last-child {
    margin: 1vh 0vw 1vh 5vw;
  }

  .properties-filter-mainselect-item {
    border-radius: 0;
  }

  .properties-slider-inner {
    width: 80%;
    margin: 0 0 0 10%;
  }
}
</style>
