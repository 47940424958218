<template>
  <div class="property-energy-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="34.927" viewBox="0 0 100 34.927" class="property-energy-item" :class="{ selected: valueA }">
      <g id="Group_2" data-name="Group 2" transform="translate(-1455.331 -1399.645)">
        <path id="Path_34" data-name="Path 34" d="M426.437,36.127H494.6L462.763,1.2H394.6Z" transform="translate(1060.731 1398.445)" fill="#02973b" />
        <text id="A" transform="translate(1505 1426)" fill="#fff" font-size="24" font-family="Roboto-Bold, Roboto" font-weight="700">
          <tspan x="-8.074" y="0">A</tspan>
        </text>
      </g></svg
    ><svg xmlns="http://www.w3.org/2000/svg" width="100" height="34.927" viewBox="0 0 100 34.927" class="property-energy-item" :class="{ selected: valueB }">
      <g id="Group_2" data-name="Group 2" transform="translate(-1455.331 -1399.645)">
        <path id="Path_34" data-name="Path 34" d="M426.437,36.127H494.6L462.763,1.2H394.6Z" transform="translate(1060.731 1398.445)" fill="#02973b" />
        <text id="A" transform="translate(1505 1426)" fill="#fff" font-size="24" font-family="Roboto-Bold, Roboto" font-weight="700">
          <tspan x="-8.074" y="0">B</tspan>
        </text>
      </g></svg
    ><svg xmlns="http://www.w3.org/2000/svg" width="100" height="34.927" viewBox="0 0 100 34.927" class="property-energy-item" :class="{ selected: valueC }">
      <g id="Group_2" data-name="Group 2" transform="translate(-1455.331 -1399.645)">
        <path id="Path_34" data-name="Path 34" d="M426.437,36.127H494.6L462.763,1.2H394.6Z" transform="translate(1060.731 1398.445)" fill="#02973b" />
        <text id="A" transform="translate(1505 1426)" fill="#fff" font-size="24" font-family="Roboto-Bold, Roboto" font-weight="700">
          <tspan x="-8.074" y="0">C</tspan>
        </text>
      </g></svg
    ><svg xmlns="http://www.w3.org/2000/svg" width="100" height="34.927" viewBox="0 0 100 34.927" class="property-energy-item" :class="{ selected: valueD }">
      <g id="Group_2" data-name="Group 2" transform="translate(-1455.331 -1399.645)">
        <path id="Path_34" data-name="Path 34" d="M426.437,36.127H494.6L462.763,1.2H394.6Z" transform="translate(1060.731 1398.445)" fill="#02973b" />
        <text id="A" transform="translate(1505 1426)" fill="#fff" font-size="24" font-family="Roboto-Bold, Roboto" font-weight="700">
          <tspan x="-8.074" y="0">D</tspan>
        </text>
      </g>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="34.927" viewBox="0 0 100 34.927" class="property-energy-item" :class="{ selected: valueE }">
      <g id="Group_2" data-name="Group 2" transform="translate(-1455.331 -1399.645)">
        <path id="Path_34" data-name="Path 34" d="M426.437,36.127H494.6L462.763,1.2H394.6Z" transform="translate(1060.731 1398.445)" fill="#02973b" />
        <text id="A" transform="translate(1505 1426)" fill="#fff" font-size="24" font-family="Roboto-Bold, Roboto" font-weight="700">
          <tspan x="-8.074" y="0">E</tspan>
        </text>
      </g>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="34.927" viewBox="0 0 100 34.927" class="property-energy-item" :class="{ selected: valueF }">
      <g id="Group_2" data-name="Group 2" transform="translate(-1455.331 -1399.645)">
        <path id="Path_34" data-name="Path 34" d="M426.437,36.127H494.6L462.763,1.2H394.6Z" transform="translate(1060.731 1398.445)" fill="#02973b" />
        <text id="A" transform="translate(1505 1426)" fill="#fff" font-size="24" font-family="Roboto-Bold, Roboto" font-weight="700">
          <tspan x="-8.074" y="0">F</tspan>
        </text>
      </g>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="34.927" viewBox="0 0 100 34.927" class="property-energy-item" :class="{ selected: valueG }">
      <g id="Group_2" data-name="Group 2" transform="translate(-1455.331 -1399.645)">
        <path id="Path_34" data-name="Path 34" d="M426.437,36.127H494.6L462.763,1.2H394.6Z" transform="translate(1060.731 1398.445)" fill="#02973b" />
        <text id="A" transform="translate(1505 1426)" fill="#fff" font-size="24" font-family="Roboto-Bold, Roboto" font-weight="700">
          <tspan x="-8.074" y="0">G</tspan>
        </text>
      </g>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="34.927" viewBox="0 0 100 34.927" class="property-energy-item" :class="{ selected: valueH }">
      <g id="Group_2" data-name="Group 2" transform="translate(-1455.331 -1399.645)">
        <path id="Path_34" data-name="Path 34" d="M426.437,36.127H494.6L462.763,1.2H394.6Z" transform="translate(1060.731 1398.445)" fill="#02973b" />
        <text id="A" transform="translate(1505 1426)" fill="#fff" font-size="24" font-family="Roboto-Bold, Roboto" font-weight="700">
          <tspan x="-8.074" y="0">H</tspan>
        </text>
      </g>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="34.927" viewBox="0 0 100 34.927" class="property-energy-item" :class="{ selected: valueI }">
      <g id="Group_2" data-name="Group 2" transform="translate(-1455.331 -1399.645)">
        <path id="Path_34" data-name="Path 34" d="M426.437,36.127H494.6L462.763,1.2H394.6Z" transform="translate(1060.731 1398.445)" fill="#02973b" />
        <text id="A" transform="translate(1505 1426)" fill="#fff" font-size="24" font-family="Roboto-Bold, Roboto" font-weight="700">
          <tspan x="-8.074" y="0">I</tspan>
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valueA: false,
      valueB: false,
      valueC: false,
      valueD: false,
      valueE: false,
      valueF: false,
      valueG: false,
      valueH: false,
      valueI: false,
    };
  },
  props: {
    value: String,
  },
  mounted() {
    if (this.value == "A") {
      this.valueA = true;
    }
    if (this.value == "B") {
      this.valueB = true;
    }
    if (this.value == "C") {
      this.valueC = true;
    }
    if (this.value == "D") {
      this.valueD = true;
    }
    if (this.value == "E") {
      this.valueE = true;
    }
    if (this.value == "F") {
      this.valueF = true;
    }
    if (this.value == "G") {
      this.valueG = true;
    }
    if (this.value == "H") {
      this.valueH = true;
    }
    if (this.value == "I") {
      this.valueI = true;
    }
  },
};
</script>

<style>
.property-energy-container {
  display: flex;
}

.property-energy-item {
  margin-right: -2vw;
  width: 5vw;
}

.property-energy-item:nth-child(1) path {
  fill: #02973b;
}

.property-energy-item:nth-child(2) path {
  fill: #4dab3f;
}

.property-energy-item:nth-child(3) path {
  fill: #50cb54;
}

.property-energy-item:nth-child(4) path {
  fill: #c4d549;
}

.property-energy-item:nth-child(5) path {
  fill: #f1d905;
}

.property-energy-item:nth-child(6) path {
  fill: #f9a526;
}

.property-energy-item:nth-child(7) path {
  fill: #fe6841;
}

.property-energy-item:nth-child(8) path {
  fill: #ea2c2c;
}

.property-energy-item:nth-child(9) path {
  fill: #79232f;
}

.property-energy-item text {
  opacity: 0;
}
.property-energy-item.selected {
  transform: scale(1.5);
}
.property-energy-item.selected text {
  opacity: 1;
}

@media only screen and (max-width: 1000px) {
  .property-energy-energy {
    padding-right: 20vw;
  }
  .property-energy-isolation {
    padding-right: 20vw;
  }

  .property-energy-energy h2 {
    padding: 5vh 0 0 0;
  }

  .property-energy-container {
    margin: 0 0 0 0;
    width: 70vw;
  }

  .property-energy-item {
    margin-right: -4vw;
    width: 10vw;
  }
}
</style>
