<template>
  <Teleport to="body" v-if="show">
    <div class="banner-container">
      <div class="banner">
        <img src="@/assets/icons/banner/privacy.svg" class="banner-icon" />
        <div class="banner-text">
          <h1>We value your privacy</h1>
          <p>This website uses cookies to ensure<br />you get the best experience on our website.</p>
        </div>
        <button class="banner-button" @click="accept()">Accept</button>
        <a href="/page/politique" class="banner-link">Read more</a>
      </div>
    </div>
  </Teleport>
</template>

<script src="./js/co-cookie-banner.js"></script>

<style scoped>
@import "./css/co-cookie-banner.css";
</style>
