export default {
  name: "CookieBanner",
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    if (localStorage.getItem("immeck_cookiebanner") != null) {
      if (localStorage.getItem("immeck_cookiebanner") == "false") {
        this.show = true;
      }
    } else {
      localStorage.setItem("immeck_cookiebanner", "false");
      this.show = true;
    }
  },
  methods: {
    accept() {
      localStorage.setItem("immeck_cookiebanner", "true");
      this.show = false;
    },
  },
};
