<template>
  <div class="pb-pagehomeprpts">
    <div class="prpts-item-container">
      <PropertyItem
        v-for="property in cdcs"
        :key="property.id"
        @click="openProperty(property.id)"
        :id="property.id"
        :Location="property.attributes.Location"
        :Sell="property.attributes.Sell"
        :Nature="property.attributes.Nature"
        :Price="property.attributes.Price"
        :Surface="property.attributes.Surface"
        :Bedrooms="property.attributes.Bedrooms"
        :Bathrooms="property.attributes.Bathrooms"
        :Parking="property.attributes.Parking"
        :SurfaceArea="property.attributes.SurfaceArea"
        :PropertyPhotoUrl="property.attributes.Photo[0].PropertyPhotoUrl"
      >
      </PropertyItem>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
import PropertyItem from "@/components/co-property-item/co-property-item.vue";

export default {
  name: "pbpagehomeprpts",
  props: {
    schema: {
      type: Object,
    },
  },
  components: {
    PropertyItem,
  },
  data() {
    return {
      text: "",
      cdcs: [],
      cdcsl: [],
    };
  },
  mounted() {
    if (this.schema.Text != null) this.text = this.schema.Text;

    axios.get(process.env.VUE_APP_CMS_DOMAIN + "/api/properties?populate=*&sort=PropertyID:desc").then((response) => {
      let res = response.data.data;

      this.allproperties = res;
      this.filteredproperties = res;

      console.log(res);

      let props = 0;
      let propsl = 0;

      for (var i = 0; i < res.length; i++) {
        if (res[i].attributes.Sell == true) {
          if (props < 3) {
            this.cdcs.push(res[i]);
            props++;
          }
        } else {
          if (propsl < 3) {
            this.cdcsl.push(res[i]);
            propsl++;
          }
        }
      }
    });
  },
  methods: {
    openProperty(id) {
      window.location.href = "/properties/" + id;
    },
  },
};
</script>

<style>
.pb-pagehomeprpts {
  width: 100%;
  min-height: 40vh;
}

.prpts-item-container {
  margin: 5vh 0 0 0;
  padding: 0 20px 10vh 20px;

  position: relative;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  display: grid;
  gap: 20px;
}

@media only screen and (max-width: 600px) {
  .prpts-item-container {
    display: flex;
    flex-direction: column;

    padding: 0 20px 0 20px;
  }
}
</style>
