<template>
  <div class="contact-form-container">
    <div class="contact-form-info-container">
      <div class="contact-form-info-flex">
        <h2 class="contact-form-info-main space">{{ langNavigationOO }}</h2>
        <h3 class="contact-form-info-sub">{{ langNavigationOO1 }}</h3>
        <h3 class="contact-form-info-sub">{{ langNavigationOO2 }}</h3>
      </div>
      <div class="contact-form-info-flex">
        <h2 class="contact-form-info-main space">{{ langContactAddress0 }}</h2>
        <h3 class="contact-form-info-sub">{{ langContactAddress1 }}</h3>
        <h3 class="contact-form-info-sub">{{ langContactAddress2 }}</h3>
        <h3 class="contact-form-info-sub">{{ langContactAddress3 }}</h3>
      </div>
      <div class="contact-form-info-flex">
        <h2 class="contact-form-info-main space">{{ langContactInfo0 }}</h2>
        <h3 class="contact-form-info-sub">{{ langContactInfo1 }}</h3>
        <h3 class="contact-form-info-sub">{{ langContactInfo2 }}</h3>
      </div>
    </div>

    <div class="contact-form-outer">
      <div class="contact-form-flex">
        <h2>{{ pagetext.title }}</h2>
        <p>{{ pagetext.description }}</p>
      </div>
      <div class="contact-form-flex">
        <div class="contact-form-row">
          <input class="contact-form-item half" type="text" :placeholder="pagetext.firstname" v-model="form.firstname" />
          <input class="contact-form-item half" type="text" :placeholder="pagetext.lastname" v-model="form.lastname" />
        </div>
        <div class="contact-form-row">
          <input class="contact-form-item full" type="text" :placeholder="pagetext.email" v-model="form.email" />
        </div>
        <div class="contact-form-row">
          <input class="contact-form-item full" type="text" :placeholder="pagetext.phone" v-model="form.phone" />
        </div>
        <div class="contact-form-row">
          <textarea class="contact-form-item full area" :placeholder="pagetext.message" v-model="form.message"></textarea>
        </div>
        <label class="contact-form-label">{{ langRequired }}</label>
        <div class="contact-form-row">
          <input class="contact-form-item half button" type="button" :value="pagetext.button" @click="submitForm" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLocaleElement } from "@/lib/locales.js";

import emailjs from "emailjs-com";

export default {
  name: "pb-pagecontactform",
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      pagetext: {
        title: "",
        description: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        message: "",
        button: "",
      },
      form: {
        firstname: "",
        lastname: "",
        email: "",
        message: "",
      },
      langNavigationOO: "",
      langNavigationOO1: "",
      langNavigationOO2: "",

      langContactAddress0: "",
      langContactAddress1: "",
      langContactAddress2: "",
      langContactAddress3: "",

      langContactInfo0: "",
      langContactInfo1: "",
      langContactInfo2: "",

      langRequired: "",

      formTextSuccess: "",
      formTextError: "",
      formTextErrorFields: "",
    };
  },
  async mounted() {
    if (this.schema.Title != null) this.pagetext.title = this.schema.Title;
    if (this.schema.Text != null) this.pagetext.description = this.schema.Text;
    if (this.schema.FieldFirstname != null) this.pagetext.firstname = this.schema.FieldFirstname;
    if (this.schema.FieldLastname != null) this.pagetext.lastname = this.schema.FieldLastname;
    if (this.schema.FieldEmail != null) this.pagetext.email = this.schema.FieldEmail;
    if (this.schema.FieldMessage != null) this.pagetext.message = this.schema.FieldMessage;
    if (this.schema.FieldButton != null) this.pagetext.button = this.schema.FieldButton;

    this.langNavigationOO = await getLocaleElement("lang-navigation-oo");
    this.langNavigationOO1 = await getLocaleElement("lang-navigation-oo-1");
    this.langNavigationOO2 = await getLocaleElement("lang-navigation-oo-2");

    this.langContactAddress0 = await getLocaleElement("lang-contact-address-0");
    this.langContactAddress1 = await getLocaleElement("lang-contact-address-1");
    this.langContactAddress2 = await getLocaleElement("lang-contact-address-2");
    this.langContactAddress3 = await getLocaleElement("lang-contact-address-3");

    this.langContactInfo0 = await getLocaleElement("lang-contact-info-0");
    this.langContactInfo1 = await getLocaleElement("lang-contact-info-1");
    this.langContactInfo2 = await getLocaleElement("lang-contact-info-2");

    this.pagetext.phone = await getLocaleElement("lang-contact-form-phone");
    this.langRequired = await getLocaleElement("lang-contact-form-required");

    this.formTextSuccess = await getLocaleElement("lang-contact-success");
    this.formTextError = await getLocaleElement("lang-contact-error");
    this.formTextErrorFields = await getLocaleElement("lang-contact-error-fields");
  },
  methods: {
    submitForm() {
      this.$recaptchaLoaded().then(() => {
        console.log("recaptcha loaded");
        this.$recaptcha("login").then((token) => {
          if (token != null) {
            let fieldsFilled = true;
            if (this.form.firstname != "") {
              if (this.form.lastname != "") {
                if (this.form.email != "") {
                  if (this.form.message != "") {
                    const templateParams = {
                      firstname: this.form.firstname,
                      lastname: this.form.lastname,
                      email: this.form.email,
                      phone: this.form.phone,
                      message: this.form.message,
                    };

                    emailjs.send("service_gnuqk61", "template_q8p5ihq", templateParams, "mk25z6_MgqdZHbrZY").then(
                      (response) => {
                        console.log("SUCCESS!", response.status, response.text);
                        alert(this.formTextSuccess);
                      },
                      (err) => {
                        console.log("FAILED...", err);
                        alert(this.formTextError);
                      }
                    );
                  } else {
                    fieldsFilled = false;
                  }
                } else {
                  fieldsFilled = false;
                }
              } else {
                fieldsFilled = false;
              }
            } else {
              fieldsFilled = false;
            }
            if (!fieldsFilled) {
              alert(this.formTextErrorFields);
            }
          }
        });
      });
    },
  },
};
</script>

<style>
.contact-form-info-container {
  width: 80vw;
  margin: 5vh 0 0 10vw;

  display: flex;
}

.contact-form-info-flex {
  flex: 1;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
}

.contact-form-info-main {
  margin: 0;
  padding: 0;

  font-family: Roboto, sans-serif;
  font-size: 18pt;
  color: #555;
}

.contact-form-info-sub {
  margin: 0;
  padding: 0;

  font-family: Roboto, sans-serif;
  font-size: 12pt;
  font-weight: 400;
  color: #555;

  text-align: left;
}

.contact-form-outer {
  display: flex;
  width: 80vw;
  margin: 15vh 0 10vh 10vw;

  align-items: flex-start;
}

.contact-form-flex {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact-form-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 1vh 0 1vh 0;
}

.contact-form-flex p {
  text-align: left;
  width: 80%;
}

.contact-form-flex h2 {
  font-family: Roboto, sans-serif;
  font-size: 18pt;
  color: #555;
}

.contact-form-flex input {
  height: 4vh;
  border: none;
  border-bottom: 1px solid #ccc;
  font-family: Roboto, sans-serif;
  font-size: 11pt;
}

.contact-form-item.half {
  width: 48%;
}
.contact-form-item.button {
  background-color: #f0f0f0;
  transition: all 200ms ease-in-out;
}
.contact-form-item.button:hover {
  background-color: #79232f;
  color: white;
  cursor: pointer;
}

.contact-form-item.full {
  width: 99%;
}

.contact-form-item.area {
  width: 99%;
  border: none;
  min-height: 10vh;
  border-bottom: 1px solid #ccc;

  font-family: Roboto, sans-serif;
  font-size: 11pt;
}

.contact-form-label {
  color: #777;
  font-family: Roboto, sans-serif;
  font-size: 11pt;
}
</style>
