<template>
  <div class="pb-largeimage">
    <div
      class="pb-largeimage-container"
      :style="{
        backgroundImage: `url('${backgroundImage}')`,
      }"
    >
      <h2 v-if="titletext != ''" :class="{ reverse: isReverseTitle }">
        {{ titletext }}
      </h2>
      <h3 v-if="projecttext != ''" :class="{ reverse: isReverseProject }">
        {{ projecttext }}
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "pblargeimage",
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      backgroundImage: null,
      titletext: "",
      projecttext: "",
      isReverseTitle: false,
      isReverseProject: false,
    };
  },
  mounted() {
    if (this.schema.MainImage.data != null) {
      this.backgroundImage = process.env.VUE_APP_CMS_DOMAIN + this.schema.MainImage.data.attributes.url;
    }
    if (this.schema.TitleText != null) {
      this.titletext = this.schema.TitleText;
    }
    if (this.schema.TitleColor == true) {
      this.isReverseTitle = true;
    }
    if (this.schema.ProjectText != null) {
      this.projecttext = this.schema.ProjectText;
    }
    if (this.schema.ProjectColor == true) {
      this.isReverseProject = true;
    }
  },
};
</script>

<style>
.pb-largeimage-container {
  width: 100vw;
  height: 75vh;
  background-position: center;
  background-size: cover;

  display: flex;

  align-items: center;
  justify-content: center;

  position: relative;
}

.pb-largeimage-container h2 {
  text-align: center;
  color: white;

  font-family: "Roboto", sans-serif;
  font-size: 48pt;
  font-weight: 500;
}

.pb-largeimage-container h3 {
  text-align: right;
  color: white;

  font-family: "Roboto", sans-serif;
  font-size: 12pt;
  font-weight: 500;

  position: absolute;

  bottom: 0;
  right: 0;

  margin: 0;
  padding: 0 2vh 2vh 0;
}

.reverse {
  color: black !important;
}

@media only screen and (max-width: 600px) {
  .pb-largeimage-container {
    height: 70vh;
  }
}
</style>
