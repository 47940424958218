<template>
  <div>
    <div class="page-flex-footer">
      <div class="page-flex-footer-part">
        <img class="footer-logo-image" src="@/assets/logo/immeck-short-w.svg" />
        <div class="footer-mobile-social">
          <a href="https://www.instagram.com/immeckventes/" target="_blank"><img class="footer-social-icon" src="@/assets/icons/social/instagram.svg" /></a>
          <a href="https://lu.linkedin.com/company/immeck-ventes" target="_blank"><img class="footer-social-icon" src="@/assets/icons/social/linkedin.svg" /></a>
          <a href="https://www.facebook.com/Immeck.lu/" target="_blank"><img class="footer-social-icon" src="@/assets/icons/social/facebook.svg" /></a>
          <a href="mailto:info@immeck.lu" target="_blank"><img class="footer-social-icon" src="@/assets/icons/social/email.svg" /></a>
        </div>
      </div>
      <div class="page-flex-footer-part">
        <div class="footer-subcontainer">
          <h3 class="footer-openinghours-title">{{ langNavigationTitle }}</h3>
          <ul class="footer-openinghours">
            <a href="/page/home">
              <li>{{ langNavigationAccueil }}</li>
            </a>
            <a href="/properties?type=acheter">
              <li>{{ langNavigationVentes }}</li>
            </a>
            <a href="/properties?type=louer">
              <li>{{ langNavigationLocations }}</li>
            </a>
            <a href="/projects">
              <li>{{ langNavigationProjets }}</li>
            </a>
            <a href="/page/contact">
              <li>{{ langNavigationContact }}</li>
            </a>
          </ul>
        </div>
      </div>
      <div class="page-flex-footer-part">
        <h3 class="footer-openinghours-title">{{ langNavigationOO }}</h3>
        <ul class="footer-openinghours">
          <li>{{ langNavigationOO1 }}</li>
          <li>{{ langNavigationOO2 }}</li>
        </ul>
      </div>
      <div class="page-flex-footer-part row">
        <a href="https://www.instagram.com/immeckventes/" target="_blank"><img class="footer-social-icon" src="@/assets/icons/social/instagram.svg" /></a>
        <a href="https://lu.linkedin.com/company/immeck-ventes" target="_blank"><img class="footer-social-icon" src="@/assets/icons/social/linkedin.svg" /></a>
        <a href="https://www.facebook.com/Immeck.lu/" target="_blank"><img class="footer-social-icon" src="@/assets/icons/social/facebook.svg" /></a>
        <a href="mailto:info@immeck.lu" target="_blank"><img class="footer-social-icon" src="@/assets/icons/social/email.svg" /></a>
      </div>
    </div>

    <ul class="footer-list bg">
      <li>Copyright @ 2022 Immeck Ventes s.à r.l.</li>
      <li>
        <a href="/page/mentionslegales">{{ langNavigationlegal }}</a>
      </li>
      <li>
        <a href="/page/politique">{{ langNavigationprivacy }}</a>
      </li>
    </ul>
  </div>
</template>

<script src="./js/co-footer.js"></script>

<style scoped>
@import "./css/co-footer.css";
@import "./css/co-footer-mobile.css";
@import "./css/co-footer-tablet.css";
</style>
