<template>
  <div class="pb-pagetitle-container">
    <div class="pb-pagetitle">
      <h2>{{ title }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "pbpagetitle",
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      title: "",
    };
  },
  mounted() {
    if (this.schema.Title != null) this.title = this.schema.Title;
  },
};
</script>

<style>
.pb-pagetitle-container {
  padding: 0 10vw 0 10vw;

  margin: 15vh 0 0 0;
}
.pb-pagetitle {
  text-align: left;
}

.pb-pagetitle h2 {
  font-size: 20pt;
  color: black;
}
</style>
