import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { VueReCaptcha } from "vue-recaptcha-v3";
import VueGoogleMaps from "@fawmi/vue-google-maps";

createApp(App)
  .use(router)
  .use(VueReCaptcha, {
    siteKey: "6Ld3QQ4mAAAAAPB_zlakIzf6ck4i0BQHw7_lFbxN",
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true,
    },
  })
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyDQkPd0lTgO92FxKqGglLIfh0RpcS-giUI",
    },
  })
  .mount("#app");
