export default {
  props: {
    id: Number,
    Location: String,
    Sell: Boolean,
    Nature: String,
    Price: Number,
    Surface: Number,
    Bedrooms: Number,
    Bathrooms: Number,
    Parking: Number,
    SurfaceArea: String,
    PropertyPhotoUrl: String,
  },
  data() {
    return {
      property: {
        id: null,
        attributes: {
          Location: null,
          Sell: null,
          Nature: null,
          Price: null,
          Surface: null,
          Bedrooms: null,
          Bathrooms: null,
          Parking: null,
          SurfaceArea: null,
          PropertyPhotoUrl: null,
        },
      },
    };
  },
  mounted() {
    if (this.id != null) {
      this.property.id = this.id;
    }
    if (this.Location != null) {
      this.property.attributes.Location = this.Location;
    }
    if (this.Sell != null) {
      this.property.attributes.Sell = this.Sell;
    }
    if (this.Nature != null) {
      this.property.attributes.Nature = this.Nature;
    }
    if (this.Price != null) {
      this.property.attributes.Price = this.Price;
    }
    if (this.Surface != null) {
      this.property.attributes.Surface = this.Surface;
    }
    if (this.Bedrooms != null) {
      this.property.attributes.Bedrooms = this.Bedrooms;
    }
    if (this.Bathrooms != null) {
      this.property.attributes.Bathrooms = this.Bathrooms;
    }
    if (this.Parking != null) {
      this.property.attributes.Parking = this.Parking;
    }
    if (this.SurfaceArea != null) {
      this.property.attributes.SurfaceArea = this.SurfaceArea;
    }
    if (this.PropertyPhotoUrl != null) {
      this.property.attributes.PropertyPhotoUrl = this.PropertyPhotoUrl;
    }
  },
  methods: {
    getPhoto(url) {
      return {
        backgroundImage: "url(" + url + ")",
      };
    },
    generateSubtitle(type, nature) {
      if (localStorage.getItem("immeck_lang") == "en") {
        let ntr = "";
        switch (nature) {
          case "Appartement":
            ntr = "Apartment";
            break;
          case "Maison":
            ntr = "House";
            break;
          case "Commerce":
            ntr = "Commercial";
            break;
          case "Terrain":
            ntr = "Terrain";
            break;
          case "Garage":
            ntr = "Garage";
            break;
          default:
            ntr = nature;
        }

        if (type == true) {
          return ntr + " for sale";
        } else {
          return ntr + " for rent";
        }
      } else {
        if (type == true) {
          return nature + " à vendre";
        } else {
          return nature + " à louer";
        }
      }
    },
    numberWithCommas(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
  },
};
