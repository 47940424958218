<template>
  <div class="pb-pagetext">
    <div class="pb-pagetext-flex-container">
      <div class="pb-pagetext-flex-item">
        <h2>{{ title1 }}</h2>
        <h2>{{ title2 }}</h2>
      </div>
      <div class="pb-pagetext-flex-item">
        <vue-markdown :source="richtext" />
      </div>
    </div>
  </div>
</template>

<script src="./js/pb-pagetext.js"></script>

<style scoped>
@import "./css/pb-pagetext.css";
@import "./css/pb-pagetext-mobile.css";
</style>
