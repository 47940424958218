import axios from "axios";

export async function getLocaleElement(worduid) {
  let localeDB = JSON.parse(localStorage.getItem("immeck_dictionary"));

  if (localeDB == null) {
    await axios.get(process.env.VUE_APP_CMS_DOMAIN + "/api/dictionaries").then((response) => {
      let datares = response.data.data;
      let dataclean = [];

      for (var j = 0; j < datares.length; j++) {
        let word = {
          id: datares[j].attributes.WordUID,
          word_fr: datares[j].attributes.word_fr,
          word_en: datares[j].attributes.word_en,
        };
        dataclean.push(word);
      }
      localStorage.setItem("immeck_dictionary", JSON.stringify(dataclean));
      localeDB = dataclean;
    });
  }

  let locale = localStorage.getItem("immeck_lang");

  for (var i = 0; i < localeDB.length; i++) {
    if (localeDB[i].id == worduid) {
      if (locale == "fr-FR") return localeDB[i].word_fr;
      if (locale == "en") return localeDB[i].word_en;
    }
  }
  return "Undefined";
}
