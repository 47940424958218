<template>
  <div class="contact-container">
    <div class="contact-info">
      <div class="contact-info-main">
        <h2 class="contact-info-title">{{ title }}</h2>
        <h3 class="contact-info-description">{{ description1 }}</h3>
        <h3 class="contact-info-description">{{ description2 }}</h3>
        <h3 class="contact-info-description">{{ description3 }}</h3>
        <h3 class="contact-info-description">{{ description4 }}{{ lat }}{{ lng }}</h3>
      </div>
      <div class="contact-info-map" v-if="coords != {}">
        <GMapMap
          :center="coords"
          :zoom="17"
          :options="{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
          }"
        >
        </GMapMap>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pbpagecontacttitle",
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      cover: "",
      title: "",
      description1: "",
      description2: "",
      description3: "",
      description4: "",
      coords: {},
    };
  },
  mounted() {
    if (this.schema.Cover != null) {
      this.cover = process.env.VUE_APP_CMS_DOMAIN + this.schema.Cover.data.attributes.url;
    }

    if (this.schema.Title != null) this.title = this.schema.Title;
    if (this.schema.Description1 != null) this.description1 = this.schema.Description1;
    if (this.schema.Description2 != null) this.description2 = this.schema.Description2;
    if (this.schema.Description3 != null) this.description3 = this.schema.Description3;
    if (this.schema.Description4 != null) this.description4 = this.schema.Description4;
    if (this.schema.Coordinates != null) {
      let lat = this.schema.Coordinates.split(",")[0];
      let lng = this.schema.Coordinates.split(",")[1];

      this.coords = { lat: parseFloat(lat), lng: parseFloat(lng) };
    }
  },
};
</script>

<style>
.contact-background {
  width: 100vw;
  height: 60vh;

  background-size: cover;
}

.contact-info {
  width: 100vw;
  height: 40vh;

  margin-top: 10vh;

  background-color: #79232f;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.contact-info-main {
  width: 60vw;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
}

.contact-info-map {
  width: 40vw;
  height: 40vh;
}

.contact-info-title {
  color: white;
  margin: 0;
  padding: 0 0 0 10vw;

  font-family: Roboto, sans-serif;
  font-size: 28pt;
}

.contact-info-description {
  color: white;
  margin: 0;
  padding: 0 0 0 10vw;

  font-family: Roboto, sans-serif;
  font-size: 14pt;
  font-weight: 400;
}
</style>
