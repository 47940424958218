import VueMarkdown from "vue-markdown-render";

export default {
  name: "pbpagetext",
  props: {
    schema: {
      type: Object,
    },
  },

  components: {
    VueMarkdown,
  },
  data() {
    return {
      title1: "",
      title2: "",
      richtext: "",
    };
  },
  mounted() {
    if (this.schema.Line1 != null) this.title1 = this.schema.Line1;
    if (this.schema.Line2 != null) this.title2 = this.schema.Line2;
    if (this.schema.RichText != null) this.richtext = this.schema.RichText;
  },
};
