<template>
  <div class="imagify-pagebuilder">
    <div v-for="(schema, index) in componentList" :key="index">
      <component :key="index" :is="schema.type" :schema="schema"></component>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";

import Footer from "@/components/co-footer/co-footer.vue";

import pbpagetext from "@/pagebuilder/pb-pagetext/pb-pagetext.vue";

import pblargeimage from "@/pagebuilder/pb-largeimage.vue";
import pbpagetitle from "@/pagebuilder/pb-pagetitle.vue";
import pbpagebigtitle from "@/pagebuilder/pb-pagebigtitle.vue";
import pbpageformattext from "@/pagebuilder/pb-pageformattext.vue";
import pbspacer from "@/pagebuilder/pb-spacer.vue";
import pbestimation from "@/pagebuilder/pb-estimation.vue";
import pbpagehomeproperties from "@/pagebuilder/pb-pagehomeproperties.vue";
import pbpagehomepropertiesrent from "@/pagebuilder/pb-pagehomepropertiesrent.vue";
import pbpagehomeprojects from "@/pagebuilder/pb-pagehomeprojects.vue";
import pbpagecontacttitle from "@/pagebuilder/pb-pagecontacttitle.vue";
import pbpagecontactteam from "@/pagebuilder/pb-pagecontactteam.vue";
import pbpagecontactform from "@/pagebuilder/pb-pagecontactform.vue";

export default {
  data() {
    return {
      pageName: null,
      pageLocale: "fr-FR",
      setupComplete: false,
      componentList: [],
    };
  },
  components: {
    Footer,
    pblargeimage,
    pbpagetext,
    pbpagetitle,
    pbpageformattext,
    pbpagebigtitle,
    pbspacer,
    pbestimation,
    pbpagehomeproperties,
    pbpagehomepropertiesrent,
    pbpagehomeprojects,
    pbpagecontacttitle,
    pbpagecontactteam,
    pbpagecontactform,
  },
  methods: {
    setup: function () {
      // Get Page Name
      if (!this.setupComplete) {
        this.pageName = this.$route.params.page;

        if (localStorage.getItem("immeck_lang") === null) {
          localStorage.setItem("immeck_lang", this.pageLocale);
        } else {
          this.pageLocale = localStorage.getItem("immeck_lang");
        }

        let query = "/api/pages?filters[PageUID]=" + this.pageName + "&locale=" + this.pageLocale + "&populate=deep";

        axios.get(process.env.VUE_APP_CMS_DOMAIN + query).then((response) => {
          let datares = response.data.data;
          if (datares.length == 0) {
            // Page not found
            let query = "/api/pages?filters[PageUID]=404&populate=deep";
            axios.get(process.env.VUE_APP_CMS_DOMAIN + query).then((response) => {
              datares = response.data.data;
              if (datares.length == 0) {
                console.log("404 Page not found");
              } else {
                this.buildPage(datares);
              }
            });
          } else {
            this.buildPage(datares);
          }
        });

        this.setupComplete = true;
      }
    },
    buildPage: function (data) {
      let components = data[0].attributes.Content;
      for (var i = 0; i < components.length; i++) {
        components[i].type = "pb" + components[i].__component.split("pagebuilder.").pop().replaceAll("-", "");
        this.componentList.push(components[[i]]);
      }
    },
  },
  mounted() {
    this.setup();
  },
  updated() {
    this.setup();
  },
};
</script>

<style>
.imagify-pagebuilder {
  overflow-x: hidden;
}
</style>
