<template>
  <div class="pb-pagebigtitle-container">
    <div class="pb-pagebigtitle" :class="{ reverse: reverse }">
      <h2>{{ title }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "pbpagetitle",
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      title: "",
      reverse: false,
    };
  },
  mounted() {
    if (this.schema.Title != null) this.title = this.schema.Title;
    if (this.schema.Reverse == true) this.reverse = true;
  },
};
</script>

<style>
.pb-pagebigtitle-container {
  padding: 0;

  margin: 0;
}
.pb-pagebigtitle {
  text-align: left;
  margin-left: 20px;
}
.pb-pagebigtitle.reverse {
  text-align: right;
  margin-right: 20px;
}

.pb-pagebigtitle h2 {
  color: #79232f;

  font-size: 128pt;

  font-weight: 500;

  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 600px) {
  .pb-pagebigtitle-container {
    width: 100%;

    overflow: hidden;
  }

  .pb-pagebigtitle h2 {
    font-size: 48pt;
  }

  .pb-pagebigtitle h2:first-child {
    padding: 15vh 0 0 0;
  }
}
</style>
