<template>
  <div class="immeck-mdd">
    <div class="mdd-selector" @click="isOpen = !isOpen">
      <label class="mdd-current-selection noselect">{{ title }}</label>
      <img src="@/assets/icons/chevron_down.svg" class="mdd-current-selection-chevron" :class="{ open: isOpen }" />
    </div>

    <div class="mdd-dropdown" :class="{ open: isOpen }">
      <ul>
        <li class="mdd-entry" v-for="entry in dataArray" :key="entry">
          <label class="mdd-container"
            >{{ entry.entry }}
            <input type="checkbox" v-model="entry.active" />
            <span class="mdd-checkmark"></span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
    value: String,
    valueP: String,
  },
  data() {
    return {
      isOpen: false,
      title: "undefined",
      dataArray: [],
    };
  },
  mounted() {
    this.title = this.value;

    let dataArray = [];
    for (var i = 0; i < this.data.length; i++) {
      dataArray.push({ entry: this.data[i], active: false });
    }
    this.dataArray = dataArray;
  },
  methods: {
    close: function () {
      this.isOpen = false;
    },
  },
  expose: ["close"],
  watch: {
    // It listens to the change in prop name
    value: function () {
      console.log("set title to " + this.value);
      this.title = this.value;
    },
    data: function () {
      let dataArray = [];
      for (var i = 0; i < this.data.length; i++) {
        dataArray.push({ entry: this.data[i], active: false });
      }
      this.dataArray = dataArray;
    },
    dataArray: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler() {
        var countTrue = 0;
        var lastTrue = "";
        for (var i = 0; i < this.dataArray.length; i++) {
          if (this.dataArray[i].active == true) {
            countTrue++;
            lastTrue = this.dataArray[i].entry;
          }
        }
        if (countTrue > 0) {
          if (countTrue == 1) {
            this.title = lastTrue;
          } else {
            this.title = countTrue + " " + this.valueP;
          }
        } else {
          this.title = this.value;
        }

        // Emit variable back

        this.$emit("dataarray", this.dataArray);
        if (window.innerWidth < 600) {
          this.close();
        }
      },
    },
  },
};
</script>

<style>
.immeck-mdd {
  position: relative;

  display: flex;
}

.mdd-selector:hover {
  cursor: pointer;
}

.mdd-selector {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-content: flex-start;
  justify-content: center;
}

.mdd-current-selection {
  margin: 0;
  padding: 0 0 0 1vw;

  border: 0;

  font-family: "Open Sans", sans-serif;
  font-size: 10pt;

  text-align: left;
  cursor: pointer;
}

.mdd-current-selection-chevron {
  position: absolute;

  right: 5%;
  top: 25%;

  height: 50%;
}

.mdd-current-selection-chevron.open {
  transform: rotate(180deg);
}

.mdd-dropdown {
  position: absolute;
  top: 100%;

  margin-left: -1px;

  height: fit-content;
  max-height: 400px;
  overflow-y: auto;
  width: calc(100% - 1px);

  background-color: white;

  z-index: 500;

  border-left: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;

  display: none;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.mdd-dropdown.open {
  display: block;

  position: absolute;
  z-index: 1000;
}

.mdd-dropdown ul {
  margin: 0;
  padding: 0;
}

.mdd-entry {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.mdd-entry:hover {
  background-color: #ededef;
}

/* Customize the label (the mdd-container) */
.mdd-container {
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  font-size: 10pt;
  font-family: "Open Sans", sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  justify-content: space-between;

  padding: 1vh 1vw 1vh 1vw;
}

/* Hide the browser's default checkbox */
.mdd-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.mdd-checkmark {
  height: 14px;
  width: 14px;
  background-color: white;

  border-radius: 3px;
  border: 1px solid #d0d0dc;
}

/* On mouse-over, add a grey background color */
.mdd-container:hover input ~ .mdd-checkmark {
  background-color: white;
  border-radius: 3px;
  border: 1px solid #d0d0dc;
}

/* When the checkbox is checked, add a blue background */
.mdd-container input:checked ~ .mdd-checkmark {
  background-color: #79232f;
  border-radius: 3px;
  border: 1px solid #79232f;
}

/* Create the mdd-checkmark/indicator (hidden when not checked) */
.mdd-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the mdd-checkmark when checked */
.mdd-container input:checked ~ .mdd-checkmark:after {
  display: block;
}

/* Style the mdd-checkmark/indicator */
.mdd-container .mdd-checkmark:after {
  margin-left: 5px;
  margin-top: 2px;
  width: 2px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
