<template>
  <div class="pb-pagehomeprojects">
    <div class="projects-item-container">
      <div class="projects-item" v-for="project in projects" :key="project">
        <a :href="project.attributes.LinkHREF">
          <div
            class="prjct-image"
            :style="{
              'background-image': 'url(' + project.attributes.CoverUrl + ')',
            }"
          >
            <div class="prjct-description">
              <h2 class="prjct-item-title">{{ project.attributes.Name }}</h2>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "pbpagehomeprojects",
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      text: "",
      projects: [],
    };
  },
  mounted() {
    if (this.schema.Text != null) this.text = this.schema.Text;

    let query = "/api/projects?populate=*&sort=Date:desc";
    axios.get(process.env.VUE_APP_CMS_DOMAIN + query).then((response) => {
      let data = response.data.data;
      if (data.length != 0) {
        if (data.length > 3) {
          data = data.slice(0, 3);
        }
        for (var i = 0; i < data.length; i++) {
          data[i].attributes.CoverUrl = process.env.VUE_APP_CMS_DOMAIN + "/" + data[i].attributes.Cover.data.attributes.url.substring(1);

          data[i].attributes.LinkHREF = "/projects/" + data[i].attributes.ProjectUID;

          data[i].attributes.LinkDocuments = "/projects/" + data[i].attributes.ProjectUID + "/documents";
        }
        this.projects = data;
      }
    });
  },
};
</script>

<style>
.pb-pagehomeprojects {
  width: 100%;
  min-height: 40vh;
}

.projects-item-container {
  display: flex;
  height: 40vh;
}

.projects-item {
  flex: 1;
  max-width: calc(33.3vw - 1em);

  padding: 1em;
}

.projects-item:first-child {
  padding: 1em 1em 1em 0;
}

.projects-item:last-child {
  padding: 1em 0 1em 1em;
}

.projects-item:first-child .prjct-image {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.projects-item:last-child .prjct-image {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.projects-item a {
  text-decoration: none;
}

.prjct-image {
  width: 100%;
  height: 100%;

  display: flex;

  background-size: cover;
  background-position: center;
  border-radius: 8px;
}

.prjct-description {
  opacity: 0;
  background-color: rgba(121, 35, 47, 0.85);
  width: 65%;

  transition: opacity 400ms;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  flex-direction: column;
}

.prjct-image:hover > .prjct-description {
  opacity: 1;
  cursor: pointer;
}

.prjct-item-title {
  margin: 0;
  padding: 0 0 0 15%;
  color: white;

  font-family: "Roboto", sans-serif;
  font-weight: 500;

  font-size: 22pt;
}

@media only screen and (max-width: 600px) {
  .pb-pagehomeprojects {
    height: auto;
  }

  .projects-item-container {
    flex-direction: column;
    height: 150vh;
  }

  .projects-item {
    padding: 0;
    max-width: 100vw;
  }

  .prjct-image > .prjct-description {
    opacity: 0.75;
  }

  .projects-item:first-child {
    padding: 1em 0 1em 0;
  }

  .projects-item:last-child {
    padding: 1em 0 1em 0;
  }

  .projects-item .prjct-image {
    border-radius: 0;
  }
}
</style>
