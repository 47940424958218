import { createRouter, createWebHistory } from "vue-router";

import PageBuilder from "@/views/PageBuilder.vue";
import ProjectBuilder from "@/views/ProjectBuilder.vue";
import PropertyBuilder from "@/views/PropertyBuilder/PropertyBuilder.vue";

import ProjectList from "@/views/ProjectList.vue";
import ProjectDocumentsBuilder from "@/views/ProjectDocumentsBuilder.vue";

import PropertyList from "@/views/PropertyList.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/page/:page", component: PageBuilder },
    { path: "/projects", component: ProjectList },
    { path: "/projects/:project", component: ProjectBuilder },
    {
      path: "/projects/:project/documents",
      component: ProjectDocumentsBuilder,
    },
    {
      path: "/properties",
      component: PropertyList,
    },
    { path: "/properties/:property", component: PropertyBuilder },

    { path: "/", redirect: "/page/home" },
  ],
});

export default router;
