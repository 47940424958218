<template>
  <div class="main-container">
    <CookieBanner></CookieBanner>
    <Teleport to="body">
      <Transition>
        <div class="drawer-bg" v-if="drawerOpen" @click="drawerOpen = !drawerOpen"></div>
      </Transition>
    </Teleport>
    <div class="menu-container" id="menu-container">
      <div class="logo-icon-container">
        <a href="/page/home">
          <img src="@/assets/immeck-color.svg" class="logo-icon" />
        </a>
      </div>
      <div class="menu-icon-container">
        <div class="menu-languageswitcher desktop" @click="changeLang">
          <div class="menu-language-text-container">
            <label class="menu-lang" :class="{ select: langPos }">FR</label>
            <label class="menu-lang" :class="{ select: !langPos }">EN</label>
          </div>
          <img src="@/assets/icons/lang/lang-lower.svg" class="lang-lower" />
          <img src="@/assets/icons/lang/lang-upper.svg" class="lang-upper" :class="{ right: !langPos }" />
        </div>
        <img src="@/assets/menu-red.svg" class="menu-icon" @click="drawerOpen = !drawerOpen" />
      </div>
    </div>
    <div class="menu-drawer" :class="{ open: drawerOpen }">
      <div class="menu-icon-container">
        <div class="menu-languageswitcher mobile" @click="changeLang">
          <div class="menu-language-text-container">
            <label class="menu-lang" :class="{ select: langPos }">FR</label>
            <label class="menu-lang" :class="{ select: !langPos }">EN</label>
          </div>
          <img src="@/assets/icons/lang/lang-lower.svg" class="lang-lower" />
          <img src="@/assets/icons/lang/lang-upper.svg" class="lang-upper" :class="{ right: !langPos }" />
        </div>
        <img src="@/assets/menu-red.svg" class="menu-icon" @click="drawerOpen = !drawerOpen" />
      </div>
      <div class="drawer-flex drawer-left" @click="drawerOpen = !drawerOpen"></div>
      <div class="drawer-flex drawer-right">
        <ul class="drawer-menu-list">
          <a href="/page/home">
            <li>{{ langNavigationAccueil }}</li>
          </a>
          <!--<a href="/page/estimation"><li>Estimation</li></a>-->
          <a href="/properties?type=acheter">
            <li>{{ langNavigationVentes }}</li>
          </a>
          <a href="/properties?type=louer">
            <li>{{ langNavigationLocations }}</li>
          </a>
          <a href="/projects">
            <li>{{ langNavigationProjets }}</li>
          </a>
          <!--<a href="/page/news"><li>News</li></a>-->
          <a href="/page/contact">
            <li>{{ langNavigationContact }}</li>
          </a>
        </ul>

        <div class="drawer-footer">
          <a href="https://www.instagram.com/immeckventes/" target="_blank"><img class="footer-social-icon" src="@/assets/icons/social/instagram.svg" /></a>
          <a href="https://lu.linkedin.com/company/immeck-ventes" target="_blank"><img class="footer-social-icon" src="@/assets/icons/social/linkedin.svg" /></a>
          <a href="https://www.facebook.com/Immeck.lu/" target="_blank"><img class="footer-social-icon" src="@/assets/icons/social/facebook.svg" /></a>
          <a href="mailto:info@immeck.lu" target="_blank"><img class="footer-social-icon" src="@/assets/icons/social/email.svg" /></a>
        </div>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
import axios from "axios";
import { getLocaleElement } from "@/lib/locales.js";

import CookieBanner from "@/components/co-cookie-banner/co-cookie-banner.vue";

export default {
  data() {
    return {
      drawerOpen: false,
      langPos: true,
      langNavigationAccueil: "",
      langNavigationContact: "",
      langNavigationLocations: "",
      langNavigationProjets: "",
      langNavigationVentes: "",
    };
  },
  components: {
    CookieBanner,
  },
  setup() {},
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  async mounted() {
    if (localStorage.getItem("immeck_lang") != null) {
      if (localStorage.getItem("immeck_lang") == "fr-FR") {
        this.langPos = true;
      } else {
        this.langPos = false;
      }
    }
    let response = await axios.get(process.env.VUE_APP_CMS_DOMAIN + "/api/dictionaries");

    let datares = response.data.data;
    let dataclean = [];

    for (var j = 0; j < datares.length; j++) {
      let word = {
        id: datares[j].attributes.WordUID,
        word_fr: datares[j].attributes.word_fr,
        word_en: datares[j].attributes.word_en,
      };
      dataclean.push(word);
    }
    localStorage.setItem("immeck_dictionary", JSON.stringify(dataclean));

    this.langNavigationAccueil = await getLocaleElement("lang-navigation-accueil");
    this.langNavigationContact = await getLocaleElement("lang-navigation-contact");
    this.langNavigationLocations = await getLocaleElement("lang-navigation-locations");
    this.langNavigationProjets = await getLocaleElement("lang-navigation-projets");
    this.langNavigationVentes = await getLocaleElement("lang-navigation-ventes");
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      console.log("scroll");
      var element = document.getElementById("menu-container");

      if (window.scrollY > 100) {
        if (!element.classList.contains("bg")) {
          element.classList.add("bg");
        }
      } else {
        if (element.classList.contains("bg")) {
          element.classList.remove("bg");
        }
      }
    },
    changeLang() {
      this.langPos = !this.langPos;
      if (this.langPos) {
        localStorage.setItem("immeck_lang", "fr-FR");
      } else {
        localStorage.setItem("immeck_lang", "en");
      }
      location.reload();
    },
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style>
@import "../node_modules/typeface-roboto/index.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

html,
body {
  margin: 0;
  height: 100%;

  background-color: white;

  position: relative;
}

.main-container {
  min-height: 100%;
}

#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  height: 100%;
}

.drawer-bg {
  position: fixed;
  z-index: 490;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.menu-icon-container {
  position: fixed;
  z-index: 100;
  /*mix-blend-mode: difference;*/

  display: flex;

  right: 10vw;
  top: 4vh;
}

.menu-icon {
  height: 1.5em;
}

.logo-icon-container {
  position: fixed;

  left: 10vw;
  top: 4vh;

  z-index: 100;
  /*mix-blend-mode: difference;*/
}

.logo-icon {
  height: 1.5em;
}

.menu-icon:hover {
  cursor: pointer;
}

.menu-drawer {
  position: fixed;

  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;

  transform: translateX(100%);

  transition: transform 500ms;

  z-index: 500;
}

.drawer-flex {
  flex: 1;
  height: 100%;
}

.drawer-right {
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-drawer.open {
  transform: translateX(0);
}

.drawer-menu-list {
  margin: 0;
  padding: 0;

  list-style-type: none;
  font-size: 28pt;
  font-weight: 500;
  text-align: left;

  display: inline-block;
}

.drawer-menu-list li {
  line-height: 56pt;
  padding: 0 0.5em 0 0.5em;

  transition: 250ms;
}

.drawer-menu-list li:hover {
  color: white;
  background-color: #79232f;
}

.drawer-menu-list li:hover a {
  color: white;
}

.drawer-menu-list li a {
  color: black;
  text-decoration: none;
}

.drawer-footer {
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 0;

  height: 5em;

  background-color: #79232f;

  display: flex;
  align-items: center;
  justify-content: center;
}

.page-width-container {
  width: 60vw;
  margin: 0 0 0 20vw;

  display: flex;
}

.menu-languageswitcher {
  display: flex;
  position: relative;

  cursor: pointer;

  margin-right: 2vw;
  padding-top: 0.3vh;
}

.lang-lower {
  height: 2.5vh;
  position: relative;
}

.lang-upper {
  height: 2.5vh;

  position: absolute;
  left: 0;

  transition: all 250ms ease-in-out;
}

.lang-upper.right {
  left: calc(50% - 1vh);
}

.menu-language-text-container {
  position: absolute;

  top: 0.1vh;
  bottom: 0;
  left: 1vh;
  right: 1vh;

  display: flex;

  z-index: 1000;

  align-items: center;
}

.menu-lang {
  flex: 1;
  font-family: Roboto, sans-serif;
  font-weight: 600;
  font-size: 10pt;

  user-select: none;
  cursor: pointer;

  transition: color 250ms;
}

.menu-lang.select {
  color: white;
}

.drawer-menu-list a {
  text-decoration: none;
  color: initial;
}

.menu-container {
  background-color: transparent;
  position: fixed;

  width: 100%;
  height: 10vh;

  top: 0;

  z-index: 100;

  transition: background 250ms;
}

.menu-container.bg {
  background-color: rgba(255, 255, 255, 0.85);
}

.menu-languageswitcher.mobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .logo-icon-container {
    top: 4vh;
    left: 5vh;
  }

  .menu-drawer {
    left: 20vw;
  }

  .logo-icon {
    height: auto;
    width: 50vw;
  }

  .menu-icon {
    height: 2.5vh;
    margin: 0.25vh 0 0 0;
  }

  .drawer-left {
    display: none;
  }

  .drawer-footer {
    left: 0;
  }

  .drawer-menu-list li {
    font-size: 22pt;
    line-height: 44pt;
  }

  .menu-languageswitcher.desktop {
    display: none;
  }

  .menu-languageswitcher.mobile {
    display: block;
  }
}
</style>
